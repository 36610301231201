import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Logout from "../../pages/Logout/Logout";
import Wallet from "../../pages/Wallet/Wallet";
import Header from "../Header";
import Dashboard from "../../pages/dashboard";
import Profile from "../../pages/profile";
import Referral from "../../pages/referral";
import Security from "../../pages/security";
import Transactions from "../../pages/transactions";
import CommingSoon from "../commingSoon";
import Support from "../../pages/support";
import Contactus from "../../pages/contactus";
import Sidebar from "../Sidebar";
import referalTable from "../../pages/dashboard/components/referalTable/referalTable";
import changePassword from "../../pages/changePassword/changePassword";
import SwapCoin from "../../pages/SwapCoin/SwapCoin";
import KYC from "../../pages/KYC/KYC";
import buyM7Coin from "../../pages/buyM7Coin/buyM7Coin";
import notification from "../../pages/notification/notification";
import success from "../../pages/success/success";
import success_Paceage from "../../pages/success/sucess_Paceage";
// import questions from "../../pages/questions/questions";
// import subscription from '../../pages/subscription/subscription'

function Layout(props) {
  const currentPath = props.location.pathname.split("/").pop();
  return (
    <div>
      <>
        <div className="overflow-hidden">
          <div className="bg-gray-100 ">
            <div className="flex flex-col justify-center h-screen">
              <div className="flex h-screen overflow-hidden">
                <Sidebar />
                <div className="flex flex-col flex-1 w-full h-screen overflow-hidden overflow-y-auto">
                  <Header
                    history={props.history}
                    currentPath={currentPath}
                    name=""
                  />
                  <Switch>
                    <Route
                      path="/app/referalTable/:refId?"
                      component={withRouter(referalTable)}
                    />
                    <Route
                      path="/app/changePassword"
                      component={withRouter(changePassword)}
                    />
                    <Route
                      path="/contactus"
                      component={withRouter(Contactus)}
                    />
                    <Route
                      path="/app/dashboard"
                      component={withRouter(Dashboard)}
                    />
                    {/* <Route path="/app/wallet" component={withRouter(Wallet)} /> */}
                    {/* <Route
                      path="/app/buyM7Coin"
                      component={withRouter(buyM7Coin)}
                    /> */}
                    <Route
                      path="/app/profile"
                      component={withRouter(Profile)}
                    />
                    <Route
                      path="/app/referral"
                      component={withRouter(Referral)}
                    />
                    <Route
                      path="/app/security"
                      component={withRouter(Security)}
                    />
                    <Route
                      path="/app/:amount/success"
                      component={withRouter(success)}
                    />
                    <Route
                      path="/app/:amount/:packageId/success-package"
                      component={withRouter(success_Paceage)}
                    />
                    <Route
                      path="/app/support"
                      component={withRouter(Support)}
                    />
                    <Route
                      path="/app/swapCoin"
                      component={withRouter(SwapCoin)}
                    />
                    <Route
                      path="/app/notification"
                      component={withRouter(notification)}
                    />
                    <Route path="/app/KYC" component={withRouter(KYC)} />
                    <Route
                      path="/app/History"
                      component={withRouter(Transactions)}
                    />
                    <Route
                      path="/app/commingSoon"
                      component={withRouter(CommingSoon)}
                    />
                    {/* <Route
                      path="/app/questions"
                      component={withRouter(questions)}
                    /> */}
                    <Route path="/app/logout" component={withRouter(Logout)} />
                    {/* <Route path="/app/subscription" component={withRouter(subscription)} /> */}
                  </Switch>

                  <div id="footer" className="mx-4 my-2">
                    <div className="w-full px-4 lg:py-2.5 py-6 all-btn-color rounded-lg">
                      <div className="text-[13px] text-white tracking-wide space-y-2 text-center">
                        <strong>© Powered By Bruce Wayne AI Labs</strong>
                        <div className="flex items-center justify-center w-full">
                          {" "}
                          <img
                            src="assets/footerlogo.png"
                            alt=""
                            className="w-12 h-8"
                          />
                        </div>
                        <h3> Copyright © 2014-2022</h3>
                      </div>
                    </div>
                  </div>
                  {/* copyright {startYear === currentYear ? date : startYear + "-" + currentYear} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
