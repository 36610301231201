import React, { Component } from "react";
import { alertActions, userActions } from "../../_actions";
import { connect } from "react-redux";
import { MdArrowBack } from "react-icons/md";
import { CONST } from "../../_config/index";
class SwapCoin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEnterNumberButton: true,
      coinamount: "",
      coinamountError: "",
      checkBoxField: false
    };
  }
  validationPaymentCheck = () => {
    let coinamount = this.state.coinamount
    let coinamountError = ""
    let formIsValid = true;
    let checkBoxField = this.state.checkBoxField;
    let errorslogin = {};

    if (coinamount <= 0) {
      formIsValid = false;
      coinamountError = "Amount must be greater than 0";
    }

    if (!checkBoxField || checkBoxField === "") {
      formIsValid = false;
      errorslogin['checkBox'] = "select  Terms and Conditions to continue.";
    }


    if (!coinamount || coinamount === "") {
      formIsValid = false;
      coinamountError = "Cannot be empty";
    }

    this.setState({ coinamountError: coinamountError });
    return formIsValid;
  }

  handleSubmitPayment = async (e) => {
    e.preventDefault();

    this.setState({ loading: true, paymentError: null });

    fetch(`${CONST.BACKEND_URL}/payPalGateway`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        amount: this.state.coinamount,
        return_url:
          window.location.href.replace("buyM7Coin", "") + `${this.state.coinamount}` + "/success",
        cancel_url: window.location.href,
        description: "BUY",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.replace(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log('nextProps.users', nextProps.users);
    if (nextProps.users.otpSentUpdatePass) {
      // console.log('nextProps.users.otpSentUpdatePass', nextProps.users.otpSentUpdatePass);
      return {
        ...nextProps,
        fieldsUser: {},
        errorslogin: {},
        optUpdatePassword: nextProps.users.otpSentUpdatePass
          ? nextProps.users.otpSentUpdatePass
          : false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  componentDidMount() {
    this.props.dispatch(userActions.getProfileDataPi());
  }

  handelOnchangeInput = (event) => {
    if (event.target.value == "00" || event.target.value > 9999) {
    } else {
      this.setState({
        coinamount: event.target.value,
      });
    }
  };

  submitCoinSwap = () => {
    let { users } = this.props;
    let { piItem } = users;
    let m7iBalance = piItem && piItem.m7iBalance ? piItem.m7iBalance : 0
    if (m7iBalance >= 9999) {
      this.props.dispatch(alertActions.error(`Your wallet limit has been reached and you have purchased ${m7iBalance} M7i coins.`))
    } else {
      if (this.validationPaymentCheck()) {
        this.setState({ showEnterNumberButton: false });
      }
    }

  };

  handleBackButtonClick = () => {
    this.setState({ showEnterNumberButton: true });
  };

  checkBoxValidation = () => {
    this.setState({ checkBoxField: !this.state.checkBoxField })
  }

  render() {
    const { showEnterNumberButton } = this.state;
    let { users } = this.props;
    let { piItem } = users;
    let m7iBalance = piItem && piItem.m7iBalance ? piItem.m7iBalance : 0

    let mix = 9999 - m7iBalance

    console.log('noticeCount____________________', mix);

    return (
      <>
        <div className="w-full h-screen  overflow-y-auto ">
          <div
            className=" bg-no-repeat bg-right bg-cover h-screen"
            style={{ backgroundImage: `url("/imge/coin_bg.png` }}
          >
            <div className="py-10 mx-auto md:w-4/6 sm:w-full lg:w-4/6 xl:w-2/5 ">
              <div className="p-6">
                <div className="w-full rounded-lg">
                  <div className="w-full mx-auto bg-[#FFFFFF] flex justify-center items-center pb-4 rounded-lg shadow-lg">
                    <div className="w-full p-5 space-y-6">
                      <div className="pt-3">
                        {showEnterNumberButton ? (
                          <></>
                        ) : (
                          <div
                            onClick={() => this.handleBackButtonClick()}
                            className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer"
                          >
                            <MdArrowBack className="w-6 h-6 text-[#0E1F51]" />
                          </div>
                        )}
                        <h1 className="text-center text-[#0E1F51] font-semibold text-2xl">
                          Buy M7Coin (M7i)
                        </h1>
                      </div>

                      <div className="px-4 rounded-lg cursor-pointer group">
                        <div className="w-full">
                          <p className="block text-center mb-1 text-xl font-medium tracking-wide text-[#0E1F51] w-full break-all">
                            Total Amount CA${this.state && this.state.coinamount ? this.state.coinamount : '0'}
                          </p>
                          <div className="relative w-56 mx-auto space-x-2">
                            <input
                              className="  block px-4 pl-16 mx-auto py-3 h-20 mt-3 w-56 text-center leading-tight text-gray-700 text-5xl font-normal bg-[#F1F1F1] border border-gray-400 rounded-md appearance-none focus:outline-none focus:bg-white"
                              placeholder="M7i"
                              value={this.state.coinamount}
                              onChange={this.handelOnchangeInput}
                            />
                            <div className="absolute top-5 left-0">
                              <img className="w-10 h-10 rounded-full cursor-pointer" src="assets/m7-coin.png" alt="logo.png" />
                            </div>
                            {this.state.coinamountError && this.state.coinamountError ?
                              <div className="text-yellow-700 invalid-feedback text-center">
                                {this.state.coinamountError}
                              </div>
                              : null}
                          </div>


                        </div>

                        {showEnterNumberButton ? (
                          <div className="block w-full mt-10 rounded-md shadow-sm">
                            <div className="flex items-center space-x-2 py-1">
                              <input id="remember_me" name="remember_me" type="checkbox"
                                className="h-4 w-4 cursor-pointer text-[#1D173F] focus:ring-[#1D173F] border-gray-300 rounded" onClick={() => this.checkBoxValidation()} />
                              <p className="block  text-sm font-medium tracking-wide text-[#0E1F51] w-full break-all">
                                I accept <a target="_blank" href="pdf/M7 terms and condition.pdf" className="text-blue-500">Terms and Condition</a> of M7i Wallet
                              </p>
                              {/* {this.state.errorslogin["checkBox"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["checkBox"]}
                                </div>
                                : null} */}
                            </div>
                            <button
                              className="flex justify-center w-full px-3 py-2 mx-auto text-xl font-medium text-white  transition duration-150 ease-in-out border border-transparent rounded-md all-btn-color focus:outline-none"
                              type="button"
                              onClick={() => this.submitCoinSwap()}
                            >
                              Buy M7i Coin
                            </button>
                          </div>
                        ) : (
                          <div className="block w-full mt-10 rounded-md shadow-sm">
                            <button
                              className="flex justify-center w-full px-3 py-2 mx-auto text-xl font-medium text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md all-btn-color focus:outline-none"
                              type="button"
                              onClick={(e) => {
                                this.handleSubmitPayment(e);
                              }}
                            >
                              Proceed to checkout
                            </button>
                          </div>
                        )}

                        <div className="grid w-full grid-cols-3 gap-4 mt-5 rounded-md shadow-sm">
                          <button
                            className="flex justify-center w-full px-3 py-2 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none"
                            type="button"
                            onClick={() => this.setState({ coinamount: 100 })}
                          >
                            {mix && mix < 100 ? mix : 100}

                          </button>
                          <button
                            className="flex justify-center w-full px-3 py-2 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none"
                            type="button"
                            onClick={() => this.setState({ coinamount: mix && mix == 9000 ? mix : 1000 })}
                          >
                            {mix && mix < 1000 ? mix : 1000}
                          </button>
                          <button
                            className="flex justify-center w-full px-3 py-2 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none"
                            type="button"
                            onClick={() => this.setState({ coinamount: mix })}
                          >
                            {mix}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user,
  };
}
export default connect(mapStateToProps)(SwapCoin);
