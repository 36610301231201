import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { TbTransform } from "react-icons/tb";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { MdSupportAgent } from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import { IoWalletOutline } from "react-icons/io5";
import { FaRegNewspaper } from "react-icons/fa";
import { TfiWallet } from "react-icons/tfi";
// import { userActions } from "../../_actions";
import { CONST } from "../../_config"
import { authHeader } from "../../_helpers"


function Sidebar(props) {

  // const dispatch = useDispatch()
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const onClickMenu = (url) => {
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen)
    }, 150);

  }

  useEffect(() => {
    const getProfile = async () => {
      const res = await fetch(CONST.BACKEND_URL + "/getProfile", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader().Authorization,
        }
      });

      const dataRes = await res.json();
      console.log('dataRes__________________dataRes', dataRes);
      if (!dataRes.data.user.isSecurity) {
        console.log('dataRes.data.user.isSecurity');
        onClickMenu('/questions')
      }
      // setProfile(dataRes.data);
      // setBalance(dataRes && dataRes.data && dataRes.data.wallet && dataRes.data.wallet.bTWallet);
      // setAddress(dataRes && dataRes.data && dataRes.data.user && dataRes.data.user.walletAddress);
    };

    getProfile();
  });

  let { location, history } = props;
  // let { piItem } = users;
  // console.log('piItem_____________________piItem', piItem);
  // if (!piItem.user.isSecurity) {
  //   console.log('1111111111111111111111111111111');
  // }

  const logout = (url) => {
    window.sessionStorage.removeItem('user');
    history.push(url);
    setTimeout(() => {
      setNavbarOpen(!navbarOpen)
    }, 150);


  }

  const logoutSubmit = () => {

    confirmAlert({
      title: 'Are you sure you want to logout?',
      buttons: [
        {
          label: 'Logout',
          onClick: () => logout('/login')
        },
        {
          label: 'Cancel'
        }
      ]
    });
  }




  return (
    <>


      {/* Navbar Toggle */}
      <button className={` ${navbarOpen === true ? 'hidden' : 'flex'} absolute top-0 left-0 px-5 items-center justify-center  text-gray-500 focus:outline-none focus:bg-gray-800 focus:text-gray-600 z-50 h-16 lg:hidden `} onClick={() => setNavbarOpen(!navbarOpen)}>
        <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7">
          </path>
        </svg>
      </button>


      {/* Navbar transparent Background & close Button */}
      {navbarOpen ?
        <div className={` ${navbarOpen === false ? 'hidden' : ''} lg:hidden lg:flex-shrink-0 lg:static inset-0 z-50 fixed all-btn-color bg-opacity-40`} onClick={() => setNavbarOpen(!navbarOpen)}>

          <div className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600 absolute left-[250px] lg:left-80 top-0 z-20" aria-label="Close sidebar">
            <svg className="w-6 h-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12">
              </path>
            </svg>
          </div>
        </div>
        : null}

      {/* Main Navbar */}
      <div className={` ${navbarOpen === false ? 'hidden' : 'flex'} bg-[#334054] fixed left-0 inset-y-0 lg:static lg:left-auto lg:inset-auto lg:flex lg:w-80 w-64 z-50`}>

        <div className="relative flex flex-col w-64 overflow-y-auto lg:w-80">

          <div onClick={() => onClickMenu('/app/dashboard')} className="sticky top-0 z-50 flex items-center justify-center w-full py-2 px-4 bg-white border-b-2 border-[#A9A9AA]/20 cursor-pointer">
            <span className="flex items-center justify-center rounded-full ">
              <img className="flex items-center justify-center w-12" src="assets/M7CoinLogo.png" alt="logo" />
            </span>
            <span className="font-extrabold tracking-wide text-[#B58633] text-shine-400 md:text-3xl ml-2">M7 BPT</span>
          </div>

          <div className="flex flex-col flex-1 pt-1 bg-white">
            <nav className="flex-1 w-full px-5 py-4 space-y-4">

              <div onClick={() => onClickMenu('/app/dashboard')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg cursor-pointer text-[#0E1F51] hover:text-white hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/dashboard" ? "text-white all-btn-color rounded-lg" : ""}`}>
                <RiDashboardFill className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Dashboard</span>
              </div>

              {/* <div onClick={() => onClickMenu('/app/wallet')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#0E1F51] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/wallet" ? "text-white all-btn-color rounded-lg" : ""}`}>
                <TfiWallet className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Wallet</span>
              </div> */}

              {/* <div onClick={() => onClickMenu('/app/buyM7Coin')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#0E1F51] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] transition-all duration-300 ${location.pathname === "/app/buyM7Coin" ? "text-white all-btn-color rounded-lg" : ""}`}>
                <IoWalletOutline className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Buy M7 Coin (M7i)</span>
              </div> */}

              {/* <div onClick={() => onClickMenu('/app/swapCoin')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#0E1F51] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/app/swapCoin" ? "text-white all-btn-color rounded-lg" : ""}`}>
                <PiSwap className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Swap Coin</span>
              </div> */}

              <div onClick={() => onClickMenu('/app/History')} className={`flex items-center whitespace-nowrap w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#0E1F51] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/app/History" ? "text-white all-btn-color rounded-lg" : ""}`}>
                <TbTransform className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">BPT History</span>
              </div>

              <div onClick={() => onClickMenu('/app/changePassword')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#0E1F51] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/app/changePassword" ? "text-white all-btn-color rounded-lg" : ""}`}>
                <RiLockPasswordLine className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Security</span>
              </div>
              {/* <div onClick={() => window.open('/pdf/M7iWhitepaper.pdf', '_blank')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#0E1F51] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/pdf/M7iWhitepaper.pdf" ? "text-white all-btn-color rounded-lg" : ""}`}>
                <FaRegNewspaper className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">White Paper</span>
              </div> */}
              <div onClick={() => onClickMenu('/app/support')} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#0E1F51] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/app/support" ? "text-white all-btn-color rounded-lg" : ""}`}>
                <MdSupportAgent className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Support</span>
              </div>


              <div onClick={logoutSubmit} className={`flex items-center w-full h-12 px-3 mt-2 rounded-lg hover:text-white cursor-pointer text-[#0E1F51] hover:bg-gradient-to-r from-[#6F521B] to-[#CB9731] ${location.pathname === "/app/login" ? "text-white all-btn-color rounded-lg" : ""}`}>
                <AiOutlineLogout className="w-7 h-7" />
                <span className="ml-4 text-lg font-medium">Logout</span>
              </div>

            </nav>
          </div>
        </div >

      </div >
    </>
  );

}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting, user } = overview ? overview : {};
  // console.log("overviewoverviewoverviewoverview", overview);
  return {
    users,
    setting,
    user
  };
}


export default (connect(mapStateToProps)(withRouter(Sidebar)));

// export default withRouter(Sidebar);