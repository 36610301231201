import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';

class Counter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsImage: {},
            errorsImage: {},
            selectedImage: null,
            message: null
        };
    }

    componentDidMount() {
        let data2 = {
            type: "fly"
        }
        this.props.dispatch(userActions.getBptDetailsForUser(data2));
        window.scrollTo(0, 0)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log("nextProps.users", nextProps.users.filesDetails);
        if (nextProps.users.filesDetails) {
            return {
                ...nextProps,

                imageName: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageName ? nextProps.users.filesDetails.imageName : null,
                imageNameUrl: nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.imageURL ? nextProps.users.filesDetails.imageURL : null

            }
        } if (nextProps.users.tempUserSuccess) {
            console.log('1111111111111111111111111', nextProps.users.tempUserSuccess);
            return {
                ...nextProps,

                fieldsprofile: nextProps.users && nextProps.users.Profile ? nextProps.users.Profile : null
            }
        }
        else {
            return {
                ...nextProps,

            }
        }
    }
    inputImageChange = (e) => {
        e.preventDefault();
        if (e.target.files[0]) {
            const reader = new FileReader();
            this.props.dispatch(userActions.uploadImage(e.target.files[0]));
            reader.onload = (e) => {
                const imageDataURL = e.target.result;
                this.setState({ selectedImage: imageDataURL });
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    submitVoiceImageBpt = () => {

        console.log('1111111111111111111111202222222222222222231213');
        let { users } = this.props;
        let { filesDetails } = users;

        let reqData = {
            "type": "fly",
            "image1": filesDetails && filesDetails.filesDetails.imageName ? filesDetails.filesDetails.imageName : this.state.fieldsImage.image1,
            "message": this.state.message ? this.state.message : "null",
            "name": "BPT_Fly With Economy"
        }
        let data2 = {
            type: "fly"
        }
        this.props.dispatch(userActions.voiceBptImageUpload(reqData, data2));
    }


    handelMsgOnChange = (event) => {
        console.log(event.target.value)
        this.setState({ message: event.target.value });
    }



    render() {
        let { users } = this.props;
        let { getBptDetailsForUser, getSocialMediaById, filesDetails } = users;
        return (
            <>
                {getBptDetailsForUser && getBptDetailsForUser != null ?
                    <>

                        {getBptDetailsForUser && getBptDetailsForUser.status && getBptDetailsForUser.status == 2 ?

                            <div className='px-6 py-5 space-y-4 bg-white rounded-xl'>
                                <h1 className='text-xs md:text-sm text-center text-red-500'>Your document has been rejected. Please re-upload your document.</h1>

                                <h1 className='text-lg md:text-xl all-txt-color'>Please upload proof of your flying economy. Please note that the date of flight must be subsequent to M7i Subscription.</h1>
                                <div className=''>
                                    <p className='text-[#0E1F51]'>Make sure the uploaded document contains:</p>
                                </div>
                                <div className='space-y-2'>
                                    <div className='flex items-center space-x-3'>
                                        <input type="checkbox" className="w-4 h-4 accent-[#BA8A2C]"  />
                                        <p className='text-sm text-[#0E1F51]'>The date of flight; name of passenger matches your name and must confirm the “economy” class.</p>
                                    </div>
                                </div>
                                <div class="md:w-[400px] w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6" id="dropzone">
                                    <input type="file" class="absolute inset-0 w-full h-full opacity-0 z-50" onChange={this.inputImageChange} />
                                    <div class="text-center">
                                        <img class="mx-auto h-12 w-12" src={this.state.selectedImage || "https://www.svgrepo.com/show/357902/image-upload.svg"} alt="" />

                                        <h3 class="mt-2 text-sm font-medium text-gray-900">
                                            <label for="file-upload" class="relative cursor-pointer">
                                                <span className='text-[#0E1F51]'>Drag and drop</span>
                                                <span class="text-indigo-600"> or browse </span>
                                                <span className='text-[#0E1F51]'>to upload</span>
                                                <input id="file-upload" name="file-upload" type="file" class="sr-only" />
                                            </label>
                                        </h3>
                                        <p class="mt-1 text-xs text-gray-500">
                                            PNG, JPG, GIF up to 10MB
                                        </p>
                                    </div>
                                    <img src="" class="mt-4 mx-auto max-h-40 hidden" id="preview" />
                                </div>

                                <p className='text-[#0E1F51]'>If you wish to provide extra information related to completion of this Bio Preservation Task, please use the box below</p>

                                <div className="relative md:w-[400px] w-ful">
                                    <label className="block w-full mb-1 font-light text-[#0E1F51]">Message (optional)</label>
                                    <input className="block w-full px-4 py-3 mx-auto mt-2 text-sm font-normal text[#0E1F51] transition duration-150 ease-in-out bg-[#F0F0F0] border border-gray-400 rounded-md sm:leading-5 border-opacity-20 focus:outline-none focus:bg-gray-100" name="address2" placeholder="Message" value={this.state.message} type="text" onChange={this.handelMsgOnChange} />
                                </div>

                                <div className="mt-10 rounded-lg md:w-[400px] w-ful" onClick={() => this.submitVoiceImageBpt()}>
                                    <button className="w-full cursor-pointer items-center block px-10 py-3.5 text-base font-medium text-center text-white transition duration-500 ease-in-out transform shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 all-btn-color uppercase"
                                    //  onClick={() => this.submitVoiceImageBpt()} 

                                    >submit</button>
                                </div>
                            </div>
                            :
                            getBptDetailsForUser && getBptDetailsForUser.status && getBptDetailsForUser.status == 1 ?
                                <div className='px-6 py-5 space-y-4 bg-white rounded-xl flex justify-center items-center text-center'>
                                    <p> Approved (Fly With Economy)</p>
                                </div>
                                : <div className='px-6 py-5 space-y-4 bg-white rounded-xl flex justify-center items-center text-center text-green-500'>
                                    <p>Pending (Fly With Economy)</p>
                                </div>
                        }
                    </>
                    :
                    <div className='px-6 py-5 space-y-4 bg-white rounded-xl'>
                        <h1 className='text-lg md:text-xl all-txt-color'>Please upload proof of your flying economy. Please note that the date of flight must be subsequent to M7i Subscription.</h1>
                        <div className=''>
                            <p className='text-[#0E1F51]'>Make sure the uploaded document contains:</p>
                        </div>
                        <div className='space-y-2'>
                            <div className='flex items-center space-x-3'>
                                <input type="checkbox" className="w-4 h-4 accent-[#BA8A2C]"  />
                                <p className='text-sm text-[#0E1F51]'>The date of flight; name of passenger matches your name and must confirm the “economy” class.</p>
                            </div>
                        </div>
                        <div class="md:w-[400px] w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6" id="dropzone">
                            <input type="file" class="absolute inset-0 w-full h-full opacity-0 z-50" onChange={this.inputImageChange} />
                            <div class="text-center">
                                <img class="mx-auto h-12 w-12" src={this.state.selectedImage || "https://www.svgrepo.com/show/357902/image-upload.svg"} alt="" />

                                <h3 class="mt-2 text-sm font-medium text-gray-900">
                                    <label for="file-upload" class="relative cursor-pointer">
                                        <span className='text-[#0E1F51]'>Drag and drop</span>
                                        <span class="text-indigo-600"> or browse </span>
                                        <span className='text-[#0E1F51]'>to upload</span>
                                        <input id="file-upload" name="file-upload" type="file" class="sr-only" />
                                    </label>
                                </h3>
                                <p class="mt-1 text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                </p>
                            </div>
                            <img src="" class="mt-4 mx-auto max-h-40 hidden" id="preview" />
                        </div>

                        <p className='text-[#0E1F51]'>If you wish to provide extra information related to completion of this Bio Preservation Task, please use the box below</p>

                        <div className="relative md:w-[400px] w-ful">
                            <label className="block w-full mb-1 font-light text-[#0E1F51]">Message (optional)</label>
                            <input className="block w-full px-4 py-3 mx-auto mt-2 text-sm font-normal text[#0E1F51] transition duration-150 ease-in-out bg-[#F0F0F0] border border-gray-400 rounded-md sm:leading-5 border-opacity-20 focus:outline-none focus:bg-gray-100" name="address2" placeholder="Message" value={this.state.message} type="text" onChange={this.handelMsgOnChange} />
                        </div>

                        <div className="mt-10 rounded-lg md:w-[400px] w-ful" onClick={() => this.submitVoiceImageBpt()}>
                            <button className="w-full cursor-pointer items-center block px-10 py-3.5 text-base font-medium text-center text-white transition duration-500 ease-in-out transform shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 all-btn-color uppercase"
                            //  onClick={() => this.submitVoiceImageBpt()} 

                            >submit</button>
                        </div>
                    </div>
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    const { users } = state;
    const { overview, getSocialMediaById } = users ? users : {};
    const { user } = overview ? overview : {};
    return {
        users,
        user,
        getSocialMediaById
    };
}

export default connect(mapStateToProps)(Counter);
