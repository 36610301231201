import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
// import jsPDF from "jspdf";
import "jspdf-autotable";
import { isMobile } from "react-device-detect";
import { FiDownload } from "react-icons/fi";
import { MdClose, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoSendSharp } from "react-icons/io5";

class Support extends Component {
  constructor(props) {
    super(props);
    this.ticketSubmit = this.ticketSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      errorsticket: {},
      fieldsticket: {},
      fieldsAddUser: {},
      fieldTicketId: null,
      dropdownVisible: false,
      selectedValue: "",
      people: [
        { name: "Keanu Reeves", profession: "Actor" },
        { name: "Lionel Messi", profession: "Football Player" },
        { name: "Cristiano Ronaldo", profession: "Football Player" },
        { name: "Jack Nicklaus", profession: "Golf Player" },
      ]
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.isTicketCreated) {
      console.log('nextProps.users.isTicketCreated', nextProps.users.isTicketCreated);
      return {
        ...nextProps,
        fieldsticket: {},
        errorsticket: {},
      }
    }
    else {
      return {
        ...nextProps
      }
    }
  }

  componentDidMount() {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTicketList(temp));
    this.props.dispatch(userActions.getProfileDataPi());
    window.scrollTo(0, 0)
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldsticket = this.state.fieldsticket;
    let errorsticket = this.state.errorsticket;
    fieldsticket[name] = value;
    errorsticket[name] = "";
    console.log('fieldsticket___________', fieldsticket);
    this.setState({ fieldsticket, errorsticket });
  }

  // ticketSubmit(e) {
  //   e.preventDefault();
  //   if (this.handleValidationTicket()) {
  //     let { title, msg } = this.state;
  //     this.props.dispatch(userActions.createTicket({
  //       title: title,
  //       msg: msg,
  //       // email: email,
  //       // issueType: selectedValue,
  //     }, this.props));
  //   }
  // }

  ticketSubmit = async (e) => {
    // e.preventDefault();
    if (this.handleValidationTicket()) {
      let { title, msg } = this.state.fieldsticket;

      let temp = {
        "keyWord": "",
        "pageNo": this.state.pageNo,
        "size": this.state.size
      }

      try {
        await this.props.dispatch(
          userActions.createTicket({
            title: title,
            msg: msg,
          }, temp, this.props)
        );

        // Ticket submission was successful, update state to show success message.
        this.setState({ isTicketSubmitted: true });

        // Set a timeout to hide the success message after 4 seconds
        setTimeout(() => {
          this.setState({ isTicketSubmitted: false });
        }, 4000);
      } catch (error) {
        // Handle any error that occurred during ticket submission.
        console.error("Ticket submission error:", error);
      }
    }
  };




  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldsticket: {},
      errorsticket: {},
    })
    this.hideErrorcomment();
  }

  handleValidationTicket = () => {
    let fieldsticket = this.state.fieldsticket;
    let errorsticket = {};
    let formIsValid = true;

    //title
    if (!fieldsticket["title"]) {
      formIsValid = false;
      errorsticket["title"] = "The title is required!";
    }

    //msg
    if (!fieldsticket["msg"]) {
      formIsValid = false;
      errorsticket["msg"] = "The message is required";
    }
    console.log('errorsticket___________________', errorsticket);

    this.setState({ errorsticket: errorsticket });
    return formIsValid;
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }


  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTicketList(datatemp));
  }

  handleRes = () => {
    let datatemp = {
      ticketId: this.state.fieldTicketId,
      msg: this.state.fieldsticket.msgg
    }
    // console.log("datatempdatatempdatatempdatatempdatatemp", datatemp);
    this.props.dispatch(userActions.replyMsgTicket(datatemp))
  }

  TicketListById = (data) => {
    this.setState({ fieldTicketId: data.id })
    let resDataLast = {
      ticketId: data.id,
    }
    this.props.dispatch(userActions.MsgListTicketById(resDataLast));
  }


  onClose = (data) => {
    this.setState({ fieldTicketId: null })
  }
  updateTicketStatus = (data) => {

    this.setState({ fieldTicketId: data.id })
    let resDataLast = {
      ticketId: data.id,
    }
    this.props.dispatch(userActions.MsgListTicketById(resDataLast))
  }


  changeSelectedValue = (e) => {
    this.setState({ selectedValue: e.target.value })
  }


  render() {

    let { users } = this.props;
    let { ticketList, loading, ticketTotal, ticketbyList, piItem } = users;
    const { isTicketSubmitted } = this.state;

    return (
      <>
        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <main className="relative z-0 flex-1 mb-10 overflow-y-auto bg_page focus:outline-none lg:mb-0" tabIndex={0}>
          <div className="relative " id="wave">

            <div className="p-5 2xl:p-10">
              <div className="mx-auto max-w-screen-3xl md:w-full">
                <section className="grid grid-cols-12 gap-5">
                  <div className="flex-col col-span-12 px-5 py-5 bg-white rounded-lg shadow-lg 2xl:col-span-4 xl:col-span-5 lg:col-span-6 2xl:py-7 2xl:px-10">
                    {isTicketSubmitted && (
                      <h2 className='py-2 text-sm text-green-500'>
                        Thank you for contacting. Your message is now being processed, and a response will be generated shortly.
                      </h2>
                    )}
                    {/* <h2 className='py-2 text-green-500'>Thankyou for contacting. Your message is now being processed and a response will be generated shortly.</h2> */}
                    <h3 className="md:text-2xl text-lg leading-6 font-semibold text-[#0E1F51] border-b pb-3 border-[]" >Create Ticket</h3>

                    <form autoComplete="off" className="mt-4 space-y-3" onSubmit={this.ticketSubmit}>

                      <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
                        <div className="flex flex-col space-y-1 rounded-md">
                          <label className="text-sm text-[#0E1F51] font-medium">Title</label>
                          <input className={`bg-[#F1F1F1] py-2 px-3 rounded-md placeholder:text-gary-300 text-gray-600 outline-none ${!this.state.errorsticket["title"] ? "'border-opacity-20 " : "order-opacity-100  bg-gray-100"}`}
                            name="title"
                            value={this.state.fieldsticket["title"] ? this.state.fieldsticket["title"] : ''}
                            onChange={this.inputChange} placeholder="" type="text" />
                          {this.state.errorsticket["title"] ?
                            <div className="mt-1 font-serif text-red-500 invalid-feedback">
                              {this.state.errorsticket["title"]}
                            </div>
                            : null}
                        </div>
                      </div>


                      <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">
                        <div className="relative flex flex-col space-y-1 rounded-md">
                          <label className="text-sm text-[#0E1F51] font-medium">Message:</label>
                          <textarea rows={4} className={`px-3 pt-1 rounded-md bg-[#F1F1F1] text-gray-600 outline-none font-normal ${!this.state.errorsticket["msg"] ? "'border-opacity-20 " : "order-opacity-100  bg-gray-100"}`}
                            name="msg"
                            value={this.state.fieldsticket["msg"] ? this.state.fieldsticket["msg"] : ''}
                            onChange={this.inputChange} placeholder="Type message here." type="amount" />
                          {this.state.errorsticket["msg"] ?
                            <div className="mt-1 font-serif text-red-500 invalid-feedback">
                              {this.state.errorsticket["msg"]}
                            </div>
                            : null}
                        </div>
                      </div>

                      <div className="grid grid-cols-1 gap-6 pt-4 lg:grid-cols-1">
                        <span className="block w-full rounded-md">
                          <button className="flex justify-center w-full px-3 py-3 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border rounded-md border-transparent lg:w-4/6 font-bold all-btn-color focus:outline-none" type="button" data-config-id="01_primary-action" onClick={() => this.ticketSubmit()}>Create</button>
                        </span>
                      </div>
                      {/* <p className='font-serif text-center text-gray-600'>Alternatively reach us out via email at support@enterwayne.com</p> */}
                    </form>
                  </div>

                  <div className="flex-col col-span-12 mb-8 bg-white rounded-lg shadow-lg 2xl:col-span-8 lg:mb-0 xl:col-span-7 lg:col-span-6 ">
                    <div className="flex flex-wrap justify-between w-full p-5">
                      <h3 className="text-lg font-semibold leading-6 text-center text-[#0E1F51] md:text-2xl md:leading-9">Ticket History</h3>
                      {/* <button onClick={() => this.exportPDF()} className="flex items-center w-20 px-4 text-base font-semibold text-white rounded-md cursor-pointer all-btn-color hover:bg-shine-400 focus:outline-none"><span><FiDownload className='w-5 h-5 pr-1 text-white' /></span>PDF</button> */}
                    </div>
                    <div className="pb-2 overflow-hidden">
                      <div className="max-w-full overflow-x-auto ">
                        <div className="inline-block min-w-full ">
                          <div className="overflow-x-auto">

                            <table className="min-w-full border-0 divide-y divide-gray-300">
                              <thead className="bg-[#AA7E27]">
                                <tr className="">
                                  <th scope="col" className="px-6 py-2 text-sm font-medium tracking-wider text-left text-white uppercase whitespace-nowrap">#</th>
                                  <th scope="col" className="px-6 py-2 text-sm font-medium tracking-wider text-left text-white uppercase whitespace-nowrap">Title</th>
                                  {/* <th scope="col" className="px-6 py-2 text-sm font-medium tracking-wider text-left text-white uppercase whitespace-nowrap">Comment</th>
                                  <th scope="col" className="px-6 py-2 text-sm font-medium tracking-wider text-left text-white uppercase whitespace-nowrap">Email</th> */}
                                  <th scope="col" className="px-6 py-2 text-sm font-medium tracking-wider text-left text-white uppercase whitespace-nowrap">Message</th>
                                  <th scope="col" className="px-6 py-2 text-sm font-medium tracking-wider text-left text-white uppercase whitespace-nowrap">Status</th>
                                  <th scope="col" className="px-6 py-2 text-sm font-medium tracking-wider text-left text-white uppercase whitespace-nowrap">Action</th>
                                </tr>
                              </thead>

                              <tbody className='divide-y'>
                                {
                                  ticketList && ticketList.length > 0 ?
                                    ticketList.map((element, index) => (
                                      <React.Fragment key={element.id}>
                                        <tr className="bg-white bg-opacity-5">
                                          <td className="px-6 py-3 border-b whitespace-nowrap text-[14px] text-[#837BB0]">{this.state.offset + index + 1}</td>
                                          <td className="px-6 py-3 border-b whitespace-nowrap text-[14px] text-[#837BB0]">{element && element.title ? element.title : '-'}</td>
                                          {/* <td className="px-6 py-3 border-b whitespace-nowrap text-[14px] text-[#837BB0]">{element && element.comment ? element.comment : '-'}</td>
                                          <td className="px-6 py-3 border-b whitespace-nowrap text-[14px] text-[#837BB0] capitalize">{element && element.email ? element.email : '-'}</td> */}
                                          <td className="px-6 py-3 border-b whitespace-nowrap text-[14px] text-[#837BB0]">{element && element.msg ? element.msg : '-'}</td>

                                          {element && element.status && element.status == 2 ?
                                            < td className="px-6 py-3 border-b whitespace-nowrap text-[14px] text-[#D30000]">Reject</td>
                                            :
                                            element && element.status && element.status == 1 ?
                                              < td className="px-6 py-3 border-b whitespace-nowrap text-[14px] text-[#007F00]">Approved</td>
                                              :
                                              < td className="px-6 py-3 border-b whitespace-nowrap text-[14px] text-[#965E00]">Pending</td>
                                          }
                                          <td className="px-6 py-3 border-b whitespace-nowrap text-[14px] text-[#837BB0]">
                                            <button class="text-white all-btn-color font-bold py-1 px-2 mr-1 border rounded" onClick={() => this.updateTicketStatus(element)}>Reply</button>
                                          </td>

                                        </tr>
                                      </React.Fragment>))
                                    : (
                                      <tr>
                                        <td colSpan="6" className="text-center text-gray-600">No Record Found</td>
                                      </tr>)
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {
                        isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              ticketTotal && ticketTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={ticketTotal / this.state.size}
                                  marginPagesDisplayed={0}
                                  pageRangeDisplayed={2}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              ticketTotal && ticketTotal > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={ticketTotal / this.state.size}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav>
                      }

                    </div>
                  </div>
                </section>

              </div>
            </div>



            {

              this.state.fieldTicketId ?
                <>
                  <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50">
                    <div className="lg:w-[30%] w-full bg-white z-50 mx-auto rounded-lg shadow-lg">
                      <div className="flex items-center justify-between p-2 border-b">
                        <div className="flex items-center">
                          <div className="pl-2">
                            <div className="font-semibold capitalize text-lg text-[#0E1F51]">{piItem && piItem.user && piItem.user.firstName ? piItem.user.firstName : ''}</div>
                          </div>
                        </div>

                        <div>
                          <button className="inline-flex p-2 rounded-full hover:bg-indigo-50" type="button">
                            <MdClose className='w-5 h-5' onClick={() => this.onClose()} />
                          </button>
                        </div>
                      </div>
                      <div className="flex-1 px-4 py-4 overflow-y-auto custom-scroll h-96">
                        {ticketbyList && ticketbyList && ticketbyList.length > 0 ?
                          ticketbyList.map((element, index) =>

                            <>
                              {element && element.isAdmin && element.isAdmin == 0 ?
                                <div className="flex items-center mb-4">
                                  <div className="flex flex-col items-center flex-none mr-4 space-y-1">
                                    <span className="block text-xs hover:underline">Team M7</span>
                                  </div>
                                  <div className="relative flex-1 p-2 mb-2 text-white bg-indigo-400 rounded-lg">
                                    <div>{element && element.msg ? element.msg : null}</div>
                                    <div className="absolute left-0 w-2 h-2 transform rotate-45 -translate-x-1/2 bg-indigo-400 top-1/2" />
                                  </div>
                                </div>
                                : null}

                              {element && element.isAdmin && element.isAdmin == 1 ?
                                <div className="flex flex-row-reverse items-center mb-4" >
                                  <div className="flex flex-col items-center flex-none ml-4 space-y-1">
                                    <span className="block text-xs capitalize hover:underline">{piItem && piItem.user && piItem.user.firstName ? piItem.user.firstName : 'Client'}</span>
                                  </div>
                                  <div className="relative flex-1 p-2 mb-2 text-gray-800 bg-indigo-100 rounded-lg">
                                    <div>{element && element.msg ? element.msg : null}</div>
                                    <div className="absolute right-0 w-2 h-2 transform rotate-45 translate-x-1/2 bg-indigo-100 top-1/2" />
                                  </div>
                                </div> : null}
                            </>
                          )
                          : null
                        }


                      </div>
                      <div className="flex items-center p-2 border-t">
                        <div className="w-full mx-2">
                          <input className={`w-full border border-gray-200 rounded-full outline-none px-4 py-2.5`}
                            name="msgg"
                            value={this.state.fieldsticket["msgg"] ? this.state.fieldsticket["msgg"] : ''}
                            onChange={this.inputChange} placeholder="message" type="text" />
                        </div>
                        <div >
                          <button className="inline-flex p-2 rounded-full all-btn-color hover:bg-indigo-50" onClick={() => { this.handleRes() }} >
                            <IoSendSharp className="w-5 h-5 text-white" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                : null
            }



          </div >
        </main >


      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication } = state;
  return {
    users,
    authentication
  };
}
export default connect(mapStateToProps)(Support);
