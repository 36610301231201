import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import CoinList from './CoinList';
import CoinListSecond from './CoinListSecond';
// import Timer from 'otp-timer'

// import { RiFacebookFill, RiInstagramLine, RiTwitterLine } from "react-icons/ri";
import { BsArrowDownSquareFill } from "react-icons/bs";
import { MdOutlineKeyboardArrowDown, MdOutlineSwapVert } from "react-icons/md";

class SwapCoin extends Component {
  constructor(props) {
    super(props);
    this.state = {

      isModalOpenSecond: false,
      isModalOpen: false,
      selectedCoin: null,
      selectedCoinSecond: null,

      selectedCoin: {
        imageLinkUrl: '',
      },
      selectedCoinSecond: {
        imageLinkUrl: '',
      },
      fieldsUser: {},
      errorslogin: {},
      optUpdatePassword: false,

      div1Content: null,
      div2Content: null,
      calculateValue: null,
      fiestCalculateValue: null,
      secondValue: 0,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log('nextProps.users', nextProps.users);
    if (nextProps.users.otpSentUpdatePass) {
      console.log('nextProps.users.otpSentUpdatePass', nextProps.users.otpSentUpdatePass);
      return {
        ...nextProps,
        fieldsUser: {},
        errorslogin: {},
        optUpdatePassword: nextProps.users.otpSentUpdatePass ? nextProps.users.otpSentUpdatePass : false
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  componentDidMount() {
    let data = {
      id: this.state && this.state.fieldsUser && this.state.fieldsUser['fromCoin'] ? this.state.fieldsUser['fromCoin'] : null
    }
    this.props.dispatch(userActions.getUserDetails());
    this.props.dispatch(userActions.getAllCoins());
    this.props.dispatch(userActions.getM7iCoin());
    this.props.dispatch(userActions.getAllCoinsRemoveId(data));
  }


  inputChange = (e) => {
    e.preventDefault();
    let { users } = this.props;
    let { getM7iCoin } = users;
    // console.log('e______________________', e.target.name);
    if (e.target.name && e.target.name == 'first_name' && this.state.selectedCoin && this.state.selectedCoin.price) {
      console.log('selectedCoin.price', this.state.selectedCoin.price);
      const value = (this.state.selectedCoin.price * e.target.value) / getM7iCoin.price
      this.setState({ calculateValue: value })
      // console.log('value_______________________', value, getM7iCoin.price);
    } else if (e.target.name && e.target.name == 'second_name' && this.state.selectedCoin && this.state.selectedCoin.price) {
      const value = (getM7iCoin.price * e.target.value) / (this.state.selectedCoin.price)
      this.setState({ fiestCalculateValue: value })
    }

    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = this.state.errorslogin;
    fieldsUser[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorslogin });
    if (this.state.fieldsUser['fromCoin']) {
      this.handleOpen()
    }
  }


  updatePasswordSubmit = (e) => {

    if (this.handleValidationUserInfo()) {
      let data = {
        currentpassword: this.state.fieldsUser.currentpassword,
        newpassword: this.state.fieldsUser.newpassword,
        confirmnewpassword: this.state.fieldsUser.confirmnewpassword

      }
      // console.log("updatePasswordSubmit___updatePasswordSubmit:::", data);
      this.props.dispatch(userActions.updatePassword(data, this.props));
    }
  }

  updateEmailSubmit = (e) => {

    if (this.handleValidationEmail()) {
      let data = {
        email: this.state.fieldsUser.email
      }
      // console.log("changeEmail___changeEmail:::", data);
      this.props.dispatch(userActions.changeEmail(data, this.props));
    }
  }


  handleValidationEmail = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;


    //email
    if (!fieldsUser["email"]) {
      formIsValid = false;
      errorslogin["email"] = "Plz enter Email !";
    }
    // console.log("errorsUsererrorsUsererrorsUser", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }


  handleOpen = () => {
    let data = {
      id: this.state && this.state.fieldsUser && this.state.fieldsUser['fromCoin'] ? this.state.fieldsUser['fromCoin'] : null
    }
    // console.log('datadatadatadata123154564312454656486456', data);
    this.props.dispatch(userActions.getAllCoinsRemoveId(data));
  }

  submitCoinSwap = () => {
    let { users } = this.props;
    let { getM7iCoin } = users;
    console.log('this.state.calculateValue', this.state.fieldsUser, this.state.calculateValue * this.state.selectedCoin.price, this.state.selectedCoin, getM7iCoin);
    this.setState({ fieldsUser: {"first_name" :(this.state.calculateValue)}});
    this.setState({calculateValue : this.state.calculateValue * getM7iCoin.price})
  }

  handleModalToggle = () => {
    console.log('11111111111111111111111111111');
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  handleCoinSelect = (coin) => {
    this.setState({
      selectedCoin: coin,
    });
    this.handleModalToggle();
  };

  handleModalToggleSecond = () => {
    this.setState((prevState) => ({
      isModalOpenSecond: !prevState.isModalOpenSecond,
    }));
  };

  handleCoinSelectSecond = (coin) => {
    this.setState({
      selectedCoinSecond: coin,
    });
    this.handleModalToggleSecond();
  };

  handleSwapClick = () => {
    console.log('first_name ', this.state.first_name)
    const {fieldsUser} = this.state;
    let {first_name} = fieldsUser && fieldsUser.first_name ? fieldsUser.first_name : {};

    this.setState((prevState)=> ({second_name : prevState.first_name}))

    // this.setState((prevState) => ({
    //   div1Content: prevState.div2Content,
    //   div2Content: prevState.div1Content,
    // }));
  };

  newHandelValueSet = async () => {
    // console.log('data_______________________________', data);
    console.log('calculateValue', this.state.calculateValue);
    console.log('calculateValue1', this.state.selectedCoinSecond);
    let secondValueCalculate = this.state.calculateValue / this.state.selectedCoinSecond.price
    console.log('secondValueCalculate', secondValueCalculate);
    this.setState({ secondValue: secondValueCalculate })
  };
  

  // swapContent = () => {
  //   const { firstDivContent, secondDivContent } = this.state;
  //   this.setState({
  //     firstDivContent: secondDivContent,
  //     secondDivContent: firstDivContent,
  //   });
  // }

  render() {
    let { users } = this.props;
    let { getM7iCoin } = users;
    // console.log('getM7iCoin', getM7iCoin);
    const { isModalOpen, selectedCoin, isModalOpenSecond, selectedCoinSecond , div1Content, div2Content } = this.state;
    // console.log('selectedCoin', selectedCoin);
    // console.log('selectedCoinselectedCoinselectedCoin', selectedCoin);
    // console.log('selectedCoinselectedCoinselectedCoin111111111', selectedCoinSecond);
    // console.log('this.state.selectedCoinSecond', this.state.selectedCoinSecond);

    // if (this.state.selectedCoinSecond && this.state.selectedCoinSecond.price) {
    //   console.log('1231213321231332123123123123231231123132123');
    //   let data = this.newHandelValueSet()
    //   console.log('================data===========>', data);
    // }
    // console.log('this.state.secondValue', this.state.secondValue);

    return (
      <>
        <div className='w-full h-screen  overflow-y-auto '>
          <div className='focus:outline-none bg-no-repeat bg-right bg-cover h-full' style={{ backgroundImage: `url("/imge/coin_bg.png` }}  >
            {/* <div className='relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page'> */}

            {/* <div className="mx-auto md:w-4/6 sm:w-full lg:w-4/6 2xl:w-2/5"> */}
            <div className="py-10 mx-auto md:w-4/5 sm:w-full lg:w-4/5 xl:w-2/5">

              <div className='p-6'>
                <div className="w-full rounded-lg">
                  <div className="w-full mx-auto bg-[#FFFFFF] flex justify-center items-center pb-4 rounded-lg shadow-lg">
                    <div className='w-full p-2 space-y-6'>
                      <div className='pt-3'>
                        <h1 className='text-center text-[#0E1F51] font-semibold text-2xl'>Swap Coin</h1>
                      </div>

                      <div className='px-4 mt-3 rounded-lg cursor-pointer groupx'>
                        
                    
                        <div className='relative flex justify-between items-center py-2 bg-[#F1F1F1] rounded-lg'>
                          <input type="number" id="first_name" name='first_name' className="relative bg-[#F1F1F1] text-gray-900 text-lg rounded-lg block md:w-[65%] w-full py-2 px-4 outline-none" placeholder="0" required

                            // onClick={this.inputChange}
                            onChange={this.inputChange}
                            value={this.state.fieldsUser && this.state.fieldsUser.first_name ? this.state.fieldsUser.first_name : this.state.fiestCalculateValue ? this.state.fiestCalculateValue : null}
                          // value={this.state.fieldsUser && this.state.fieldsUser.first_name ? this.state.fieldsUser.first_name : selectedCoin && selectedCoin.price ? selectedCoin.price : 0}
                          // value={this.state.fieldsUser ?this.state.fieldsUser:0}
                          />
                          <p className='right-0 absolute cursor-pointer border border-[#E5E5E5] text-[#0E1F51] font-normal rounded-full md:w-[35%] focus:outline-none px-1 h-9 bg-[#E5E5E5] flex items-center justify-between mr-3' onClick={this.handleModalToggle}>
                            <span className='flex items-center text-base flex-nowrap'>
                              <img src={selectedCoin.imageLinkUrl || 'assets/bnb-bnb-logo.png'} alt='bnb-logo.png' className='mr-2 rounded-full w-7 h-7' />
                              {selectedCoin && selectedCoin.name ? `${selectedCoin.name}` : "Select Coin"}
                            </span>
                            <MdOutlineKeyboardArrowDown className="w-6 h-6" />
                          </p>
                          {isModalOpen && <CoinList onSelectCoin={this.handleCoinSelect} />}
                        </div> 
                        

                         <div className="relative flex items-center justify-center pb -mx-2" onClick={() => {this.submitCoinSwap()}}>
                             <MdOutlineSwapVert size={40} className="absolute z-40 text-white bg-[#A27827] border-4 border-[#A27827] rounded-xl" />
                        </div> 
                     <div className='relative flex justify-between items-center py-2 mt-5 bg-[#F1F1F1] rounded-lg'>
                          <input type="number" id="second_name" name='second_name' className="relative bg-[#F1F1F1] text-gray-900 text-lg rounded-lg block md:w-[65%] w-full py-2 px-4 outline-none" placeholder="0" required
                            onChange={this.inputChange}
                            value={this.state.fieldsUser && this.state.fieldsUser.second_name ? this.state.fieldsUser.second_name : this.state && this.state.calculateValue ? this.state.calculateValue : this.state.fieldsUser && this.state.fieldsUser.second_name ? this.state.fieldsUser.second_name : null}
                          />
                          <p className='right-0 absolute cursor-pointer border border-[#E5E5E5] text-[#0E1F51] font-normal rounded-full md:w-[35%] focus:outline-none px-1 h-9 bg-[#E5E5E5] flex items-center justify-between mr-3' onClick={this.handleModalToggleSecond}>
                            <span className='flex items-center text-base flex-nowrap' >
                              <img
                                src={getM7iCoin && getM7iCoin.imageLinkUrl || 'assets/bnb-bnb-logo.png'}
                                alt='bnb-logo.png'
                                className='mr-2 rounded-full w-7 h-7'
                              />
                              {getM7iCoin && getM7iCoin.name ? `${getM7iCoin.name}` : "Select Coin"}

                            </span>
                            <MdOutlineKeyboardArrowDown className="w-6 h-6" />
                          </p>
                          {isModalOpenSecond &&
                            // <div onClick={() => this.newHandelValueSet(selectedCoinSecond)}>
                            <CoinListSecond onSelectCoin={this.handleCoinSelectSecond} />
                            // </div>
                          }

                        </div>

                        <div className="relative flex items-center justify-center pb-1 -mx-2" >
                        </div>

                        {/* <div className="py-2">
                        <input type="number" name='amount' className="block w-full px-4 py-3.5 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white"
                          value={this.state.fieldsUser && this.state.fieldsUser["amount"] ? this.state.fieldsUser["amount"] : ''} placeholder="Amount" onChange={this.inputChange} />
                      </div> */}

                        <div className="block w-full pb-8 rounded-md shadow-sm mt-7">
                          <button className="flex justify-center w-full p-3 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none" type="button" onClick={() => this.submitCoinSwap()}>Swap Coin</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>


        {/* <div>
        <div className='relative flex justify-between items-center py-2 bg-[#bd2d2d] rounded-lg'>
          <div>{this.state.firstDivContent}</div>
        </div>

        <div className='relative flex justify-between items-center py-2 mt-5 bg-[#a02cb8] rounded-lg'>
          <div>{this.state.secondDivContent}</div>
        </div>

        <button onClick={this.swapContent}>Swap Content</button>
      </div> */}
        {/* {isModalOpen &&
          <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="p-4 bg-white rounded-lg shadow-md">
              <h2 className="mb-4 text-xl font-semibold">Select a coin</h2>
              <ul>
                {coins.map((coin) => (
                  <li key={coin} className="px-4 py-2 cursor-pointer hover:bg-gray-200">
                    {coin}
                  </li>
                ))}
              </ul>
            </div>
          </div>

        } */}
        {/* {
          this.state.fieldsUser['fromCoin'] ?
            <>
            </>
            : null
        } */}

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user
  };
}
export default connect(mapStateToProps)(SwapCoin);