import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { BsGlobe } from "react-icons/bs";
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
class Signup extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.registerSubmit = this.registerSubmit.bind(this);
    this.otpSubmit = this.otpSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentRegister: false,
      shown: false,
      checkBoxField: false
    }
  }

  inputChange(e) {
    e.preventDefault();
    console.log('e.target.value', e.target.value);
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  registerSubmit(e) {
    // alert("Hello! I am an alert box!!");
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let data = {
        firstName: this.state.fieldslogin.firstName,
        lastName: this.state.fieldslogin.lastName,
        mobNo: this.state.fieldslogin.mobNo,
        email: this.state.fieldslogin.email,
        password: this.state.fieldslogin.password,
        refByCode: this.props.match.params.id ? this.props.match.params.id : (this.state.fieldslogin.refByCode ? this.state.fieldslogin.refByCode : "ADMIN")
      }
      // console.log("registerSubmit___registerSubmit:::", data);
      this.props.dispatch(userActions.register(data, this.props));
    }
  }

  resendOtpSubmit = (e) => {
    // alert("Hello! I am an alert box!!");
    // e.preventDefault();
    if (this.handleValidationLogin()) {
      let data = {
        firstName: this.state.fieldslogin.firstName,
        lastName: this.state.fieldslogin.lastName,
        email: this.state.fieldslogin.email,
        // mobNo: this.state.fieldslogin.mobNo,
        password: this.state.fieldslogin.password,
        refByCode: this.props.match.params.id ? this.props.match.params.id : (this.state.fieldslogin.refByCode ? this.state.fieldslogin.refByCode : "ADMIN")
      }
      console.log("registerSubmit___registerSubmit:::", data);
      this.props.dispatch(userActions.register(data, this.props));
    }
  }


  otpSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationOTP()) {
      let { token } = this.props.registeruser;
      let { OTP } = this.state;
      this.props.dispatch(userActions.registerValidateOtp({
        token: token,
        otp: OTP
      }, this.props));
    }
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "Please enter access code!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }



  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let checkBoxField = this.state.checkBoxField;
    let errorslogin = {};
    let formIsValid = true;

    //firstName
    if (!fieldslogin["firstName"] || fieldslogin["firstName"] === "") {
      formIsValid = false;
      errorslogin["firstName"] = "Cannot be empty first name.";
    }
    if (!/^[A-Za-z]+(['\-\.]?[A-Za-z]+)?$/.test(fieldslogin["firstName"])) {
      errorslogin["firstName"] = "Please enter the first name alphabetically.";
    }

    //userName
    if (!fieldslogin["lastName"] || fieldslogin["lastName"] === "") {
      formIsValid = false;
      errorslogin["lastName"] = "Cannot be empty last name.";
    }
    if (!/^[A-Za-z]+(['\-\.]?[A-Za-z]+)?$/.test(fieldslogin["lastName"])) {
      errorslogin["lastName"] = "Please enter the last name alphabetically.";
    }

    //userName
    if (!fieldslogin["mobNo"] || fieldslogin["mobNo"] === "") {
      formIsValid = false;
      errorslogin["mobNo"] = "Cannot be empty mobile number.";
    }
    //userName
    if (!checkBoxField || checkBoxField === "") {
      formIsValid = false;
      errorslogin['checkBox'] = "Please select the Terms and Conditions to continue.";
    }

    // //Email
    if (!fieldslogin["email"] || fieldslogin["email"] === "") {
      formIsValid = false;
      errorslogin["email"] = "Enter a valid email address.";
    }
    if (typeof fieldslogin["email"] !== "undefined" && fieldslogin["email"] !== "") {
      let lastAtPos = fieldslogin["email"].lastIndexOf('@');
      let lastDotPos = fieldslogin["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldslogin["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldslogin["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorslogin["email"] = "Enter valid email!";
      }
    }
    //password
    if (!fieldslogin["password"] || !fieldslogin["password"].match(/^(?=.*[a-zA-Z0-9])(?=.*[^a-zA-Z0-9]).{6,}$/)) {
      formIsValid = false;
      errorslogin["password"] = "The password field is required and must be at least 6 characters long, containing at least one alphanumeric character and one special character.";
    }
    //confirmPassword
    // if (!fieldslogin["confirmPassword"] || !fieldslogin["confirmPassword"].match(/^(?=.*[a-zA-Z0-9])(?=.*[^a-zA-Z0-9]).{6,}$/)) {
    //   formIsValid = false;
    //   errorslogin["confirmPassword"] = "The confirmPassword field is required and must be at least 6 characters long, containing at least one alphanumeric character and one special character.";
    // }


    // refByCode
    // if (!fieldslogin["refByCode"] || !this.props.match.params.id) {
    //   formIsValid = false;
    //   errorslogin["refByCode"] = "Please enter the referral code !";
    // }

    // console.log("errorsloginerrorsloginerrorslogin", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }



  inputChangeReferal = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    if (value.length === 8) {
      // alert("Hello! I am an alert box!!");
      this.props.dispatch(userActions.getUserByRefCode({ refCode: value }));
    }
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    // console.log(name, value);

    this.setState({ fieldslogin, errorslogin });
  }


  componentDidMount() {
    let data = "";
    data = this.props.match.params.id ? this.props.match.params.id : "!!!!"
    if (this.props.match.params.id) {
      this.props.dispatch(userActions.getUserByRefCode({ refCode: this.props.match.params.id }));
      console.log('this.props.match.params.id', this.props.match.params.id);

    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }

  checkBoxValidation = () => {
    this.setState({ checkBoxField: !this.state.checkBoxField })
  }

  render() {
    let { otpSentRegister, loading, users } = this.props;
    let { userByRefCode } = users;
    console.log("this.state.fieldslogin::", this.state.fieldslogin);
    // console.log("render______this.props.match.params.id::", this.props.match.params.id);
    return (
      <>

        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <>

          {/* Whole Structure */}

          <div className="overflow-hidden">
            <div className="bg-gray-100 ">
              <div className="flex flex-col justify-center h-screen">
                <div className="flex h-screen overflow-hidden" >
                  <div className="flex flex-col flex-1 w-full h-screen overflow-hidden overflow-y-auto">

                    <div className="flex w-full h-screen md:overflow-hidden overflow-y-auto md:items-center md:bg-[#1D173F]">

                      <div className="items-center justify-center hidden w-full h-screen bg-[1D173F] lg:flex">
                        <div className="w-full ">
                          <div className="flex justify-center pt-2">
                            <div className="space-y-2">
                              <div className="flex items-center justify-center w-full">
                                <img className="w-24 h-24 lg:w-28 lg:h-28 lg:ml-10" src="assets/M7CoinLogo.png" alt="M7CoinLogo.png" />
                              </div>
                              <h1 className="text-4xl font-extrabold text-white lg:text-5xl">M7<span className="ml-2 text-[#B58633]">Coin  Web Wallet</span></h1>
                            </div>
                          </div>
                          <div className="bg-gray-600 h-0.5 w-74 ml-8 mr-10 lg:mt-14 mt-6"></div>
                          <div className="hidden py-5 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                            <div className="hidden px-4 py-5 space-y-3 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                              <h1 className="text-2xl font-bold text-center text-white lg:text-2xl">Bio Preservation Project</h1>
                              <h1 className="text-lg text-center text-gray-400">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                              <h1 className="flex items-center justify-center text-sm text-center text-gray-400 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-400" />enterwayne.com</a></h1>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="flex items-center justify-center w-full h-screen overflow-y-auto py-10 space-y-3 bg_page">
                        <div className="w-full py-20">


                          <div className="flex-col w-5/6 mx-auto space-y-2 md:w-5/6 pt-10">
                            <div className="flex lg:hidden lg:justify-center ">
                              <div className="mx-auto">
                                <img className="w-20 h-20 mx-auto" src="assets/M7CoinLogo.png" />
                                <h1 className="py-4 text-3xl text-[#B58633] font-extrabold md:text-4xl">M7<span className="ml-2 text-[#B58633]">COIN</span>
                                </h1>
                              </div>
                            </div>
                            <div className="w-full lg:py-4 py-0">
                              <h1 className="sign-text">Sign up</h1>
                            </div>
                            <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                              <div className="">
                                <label className="text-sm font-medium text-gray-600">First Name</label>
                                <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["firstName"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                  id="firstName" name="firstName" placeholder="" type="firstName" onChange={this.inputChange} />
                                {this.state.errorslogin["firstName"] ?
                                  <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                    {this.state.errorslogin["firstName"]}
                                  </div>
                                  : null}
                              </div>


                              <div className="">
                                <label className="text-sm font-medium text-gray-600">Last Name</label>
                                <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["lastName"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                  id="lastName" name="lastName" placeholder="" type="lastName" onChange={this.inputChange} />
                                {this.state.errorslogin["lastName"] ?
                                  <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                    {this.state.errorslogin["lastName"]}
                                  </div>
                                  : null}
                              </div>
                            </div>
                            <div className="">
                              <label className="text-sm font-medium text-gray-600">Email</label>
                              <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["email"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="email" name="email" placeholder="" type="email" onChange={this.inputChange} />
                              {this.state.errorslogin["email"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["email"]}
                                </div>
                                : null}
                            </div>

                            <div className="">
                              <label className="text-sm font-medium text-gray-600">Mobile No.</label>
                              <input disabled={otpSentRegister ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["mobNo"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="mobNo" name="mobNo" placeholder="" type="number" onChange={this.inputChange} />
                              {this.state.errorslogin["mobNo"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["mobNo"]}
                                </div>
                                : null}
                            </div>
                            <div className="">
                              <label className="text-sm font-medium text-gray-600"> Password</label>
                              <div className="relative">
                                <input
                                  disabled={otpSentRegister ? true : false}
                                  className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["password"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                  id="password"
                                  name="password"
                                  placeholder=""
                                  type={this.state.showPassword ? "text" : "password"}
                                  onChange={this.inputChange}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      this.loginSubmit();
                                    }
                                  }}
                                />
                                <button
                                  onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                  className="absolute right-4 top-1/2 transform -translate-y-1/2 focus:outline-none"
                                >
                                  {this.state.showPassword ? <RiEyeOffLine /> : <RiEyeLine />}
                                </button>
                              </div>
                              {this.state.errorslogin["password"] &&
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["password"]}
                                </div>
                              }
                            </div>
                           

                            <div>
                              <div class="flex items-center px-2 ">
                                <div class="flex cursor-pointer">
                                  <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 cursor-pointer text-[#1D173F] focus:ring-[#1D173F] border-gray-300 rounded" onClick={() => this.checkBoxValidation()} />
                                  <label for="remember_me" class="block ml-2 text-sm text-gray-700">By continuing, I agree to M7's <a target="_blank" href="pdf/M7_Privacy_Policy.pdf" class="text-blue-500 hover:underline font-bold">Privacy Policy</a> and <a target="_blank" href="pdf/Terms_&_Condtions_M7.pdf" class="text-blue-500 hover:underline font-bold">Terms & Conditions</a></label>
                                </div>
                              </div>
                              {this.state.errorslogin["checkBox"] ?
                                <div class="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["checkBox"]}
                                </div>
                                : null}
                            </div>
                            {
                              otpSentRegister ?
                                <div className="">
                                  <div className="">
                                    <label className="text-sm font-medium text-gray-600">Access Code</label>
                                  </div>
                                  <input className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base placeholder:font-normal font-medium text-black placeholder-white placeholder:text-[#A09DC5] ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-gray-700" : "border-opacity-100 border border-red-500"}`} id="OTP" name="OTP"
                                    value={this.state.fieldslogin["OTP"] ? this.state.fieldslogin["OTP"] : ''}
                                    onChange={this.inputChange} placeholder="" type="text"
                                  />
                                  {this.state.errorslogin["OTP"] ?
                                    <div className="w-5/6 invalid-feedback text-red-500">
                                      {this.state.errorslogin["OTP"]}
                                    </div>
                                    : null}
                                </div>
                                : null
                            }
                          </div>

                          {otpSentRegister ?
                            <div className="flex items-center justify-between w-5/6 mx-auto  mr-auto md:w-3/6 ">
                              <div className="flex items-center py-2 ml-2 lg:ml-0">
                                <Timer textColor={""} seconds={1} minutes={2} ButtonText="Resend Otp" resend={this.resendOtpSubmit} />
                              </div>
                            </div> : null}

                          {otpSentRegister ?
                            <div className="mt-2 text-center lg:py-6 py-0 ">
                              <button className="w-5/6 py-2 text-white transition duration-150 ease-in-out rounded-lg cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline md:w-3/6 lg:py-3" type="button" onClick={this.otpSubmit} >Access Code</button>
                            </div>

                            :
                            <div className="mt-2 text-center lg:py-6 py-0">
                              <button className="w-5/6 py-2 mx-auto text-white transition duration-150 ease-in-out rounded-lg cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline lg:py-3 md:w-3/6" type="submit" onClick={this.registerSubmit}>Sign Up</button>
                            </div>
                          }


                          <h1 className="text-[#A09DC5] text-center mt-3 lg:mt-8  text-[16px]">Already have an account? <span onClick={() => this.onClickMenu('/login')} className="text-[#CB9731] cursor-pointer lg:text-sm text-[15px]">Sign In</span></h1>

                          {/* <div className="px-4 mt-4 space-y-2 lg:hidden lg:flex lg:flex-col">
                            <h1 className="px-5 text-[1rem] font-medium text-center text-gray-600 lg:pt-5">Bio Preservation Project</h1>
                            <h1 className="text-[0.9rem] text-center text-gray-600 lg:pt-5">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                            <h1 className="flex items-center justify-center text-sm text-center text-gray-600 lg:pt-2 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-600" />enterwayne.com</a></h1>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div id="footer" className="">
                      <div className="w-full px-4 py-2.5 all-btn-color">
                        <div className="text-[13px] text-white tracking-wide space-y-2 text-center pb-6">
                          <strong>© Powered By Bruce Wayne AI Labs</strong>
                          <div className="flex items-center justify-center w-full">  <img src="assets/footerlogo.png" alt="" className="w-12 h-8" /></div>
                          <h3> Copyright © 2014-2023</h3>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


        </>

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentRegister, registeruser, loading } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    registeruser,
    otpSentRegister,
    user,
    users,
    loading
  };
}
export default connect(mapStateToProps)(Signup);
// export default NetworkDetector(connect(mapStateToProps)(Login));