import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { dashboardActions } from "../../_actions";
import { useDispatch } from "react-redux";
import { MdOutlineClose } from "react-icons/md";


function CoinList(props) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardActions.getCoinList());
  }, [])

  const handleCoinSelect = (coin) => {
    props.onSelectCoin(coin);
  };

  const closeHandleModule = () => {
    props.history.push('/app/swapcoin')
  };

  const { dashboard } = props;
  const { coinItems } = dashboard;

  console.log('coinItemscoinItemscoinItemscoinItemscoinItemscoinItems', coinItems);

  return (

    <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50">
      <div className="md:w-[25rem] w-full md:h-auto h-[560px] rounded-2xl bg-white">
        <div className="flex items-center justify-between w-full px-6 mt-4 " >
          <h3 className="text-lg text-[#0E1F51]">Select Coin</h3>
          <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
            <MdOutlineClose className="h-6 w-6 cursor-pointer text-[1.3rem] text-[#0E1F51]" onClick={() => closeHandleModule()} />
          </div>
        </div>

        <div className="px-4 py-3 border-b border-slate-600" >
          <div className="relative" >
            <div className="absolute inset-y-0 left-0 flex items-center px-2 mr-1 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" >
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input type="search" id="default-search" className="block w-full px-6 py-2 mb-2 text-sm font-normal text-white border rounded-lg outline-none border-slate-600 bg-white/10" placeholder="Search name or paste address" required />
          </div>
        </div>
        <div className="px-4 py-2 space-y-2 overflow-auto h-96 custom-scroll scrollable-element">
          {coinItems && coinItems.map((coin) => (
            <>
              {coin && coin.coinFullName && coin.ticker != "M7i" ?

                < div className="flex items-center text-[#0E1F51]" onClick={() => handleCoinSelect(coin ? coin : null)}>
                  <img className="w-12 h-12" src={coin.imageLinkUrl && coin.imageLinkUrl ? coin.imageLinkUrl : null} alt="logo.png" />
                  <div>
                    <p className='ml-3 text-base text-[#0E1F51]'>{coin && coin.coinFullName ? coin.coinFullName : null}</p>
                    <p className='ml-3 text-xs text-[#837BB0]'>{coin && coin.name ? coin.name : null}</p>
                  </div>
                </div>

                : null
              }
            </>
          ))}
        </div>
      </div>
    </div >

  );
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { user } = state.authentication;
  // console.log("mapStateToProps___________user:::", user);
  return {
    users,
    user,
    dashboard
  };
}
export default withRouter(connect(mapStateToProps)(CoinList));
// export default withRouter(Sidebar);
