import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';


class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showPasswordNew: false,
      showPasswordConfirm: false,
      fieldsUser: {},
      errorslogin: {},
      optUpdatePassword: false

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('nextProps.users', nextProps.users);
    if (nextProps.users.otpSentUpdatePass) {
      console.log('nextProps.users.otpSentUpdatePass', nextProps.users.otpSentUpdatePass);
      return {
        ...nextProps,
        fieldsUser: {},
        errorslogin: {},
        optUpdatePassword: nextProps.users.otpSentUpdatePass ? nextProps.users.otpSentUpdatePass : false
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  componentDidMount() {
    this.props.dispatch(userActions.getProfileDataPi());
  }


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = this.state.errorslogin;
    fieldsUser[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldsUser, errorslogin });
  }


  updatePasswordSubmit = (e) => {

    if (this.handleValidationUserInfo()) {
      let data = {
        currentpassword: this.state.fieldsUser.currentpassword,
        newpassword: this.state.fieldsUser.newpassword,
        confirmnewpassword: this.state.fieldsUser.confirmnewpassword

      }
      this.props.dispatch(userActions.updatePassword(data, this.props));
    }
  }

  updateEmailSubmit = (e) => {

    if (this.handleValidationEmail()) {
      let data = {
        email: this.state.fieldsUser.email
      }
      this.props.dispatch(userActions.changeEmail(data, this.props));
    }
  }


  handleValidationEmail = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;


    //email
    if (!fieldsUser["email"]) {
      formIsValid = false;
      errorslogin["email"] = "Plz enter Email !";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  handleValidationUserInfo = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;


    //currentpassword
    if (!fieldsUser["currentpassword"]) {
      formIsValid = false;
      errorslogin["currentpassword"] = "Invalid Password!";
    }

    //newpassword
    if (!fieldsUser["newpassword"]) {
      formIsValid = false;
      errorslogin["newpassword"] = "Invalid New Password!";
    }

    //confirmnewpassword
    if (!fieldsUser["confirmnewpassword"]) {
      formIsValid = false;
      errorslogin["confirmnewpassword"] = "Invalid Confirm New Password!";
    }


    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  handleValidationUserInfo2 = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorslogin = {};
    let formIsValid = true;

    //newpassword
    if (!fieldsUser["newpassword"] || !fieldsUser["newpassword"].match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")) {
      formIsValid = false;
      errorslogin["newpassword"] = "Invalid Password!";
    }

    //confirmnewpassword
    if (!fieldsUser["confirmnewpassword"] || !fieldsUser["confirmnewpassword"].match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")) {
      formIsValid = false;
      errorslogin["confirmnewpassword"] = "Invalid confirmnewpassword!";
    }

    //currentpassword
    if (!fieldsUser["currentpassword"] || fieldsUser["currentpassword"] === "") {
      formIsValid = false;
      errorslogin["currentpassword"] = "Cannot Be Empty";
    }


    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }



  render() {
    return (
      <>
        <div className='w-full h-screen  overflow-y-auto overflow-hidden'>
          <div className='focus:outline-none bg-no-repeat bg-right bg-cover h-screen' style={{ backgroundImage: `url("/imge/coin_bg.png` }}  >

            <div className="py-6 mx-auto md:w-4/6 sm:w-full lg:w-4/6 xl:w-2/5">

              <div className='p-6'>
                <div className="w-full rounded-sm">
                  <div className="w-full mx-auto bg-[#FFFFFF] flex justify-center items-center pb-4 rounded-lg shadow-lg">
                    <div className='w-full p-4 space-y-2'>
                      <h1 className='pb-2 text-2xl font-semibold text-[#314498]'>Change Password</h1>
                      <div className="w-full">
                        <label className="block mb-1 text-sm font-medium tracking-wide text-[#0E1F51]" htmlFor="grid-first-name">Current Password</label>
                        <div className="relative">
                          <input
                            className="block w-full px-4 py-3 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-[#F1F1F1] rounded-md appearance-none focus:outline-none focus:bg-white pr-12"
                            name="currentpassword"
                            type={this.state.showPassword ? "text" : "password"}
                            placeholder='Current Password'
                            value={this.state.fieldsUser && this.state.fieldsUser["currentpassword"] ? this.state.fieldsUser["currentpassword"] : ''}
                            onChange={this.inputChange}
                          />
                          <button
                            className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500"
                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                          >
                            {this.state.showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                          </button>
                        </div>
                        {this.state.errorslogin["currentpassword"] ?
                          <div className="text-xs italic text-red-500 invalid-feedback">
                            {this.state.errorslogin["currentpassword"]}
                          </div>
                          : null}
                      </div>
                      <div className="w-full mt-4">
                        <label className="block mb-1 text-sm font-medium tracking-wide text-[#0E1F51]" htmlFor="grid-first-name">New Password</label>
                        <div className="relative">
                          <input
                            className="block w-full px-4 py-3 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-gray-200 rounded-md appearance-none focus:outline-none focus:bg-white pr-12"
                            name="newpassword"
                            type={this.state.showPasswordNew ? "text" : "password"}
                            placeholder='New Password'
                            value={this.state.fieldsUser && this.state.fieldsUser["newpassword"] ? this.state.fieldsUser["newpassword"] : ''}
                            onChange={this.inputChange}
                          />
                          <button
                            className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500"
                            onClick={() => this.setState({ showPasswordNew: !this.state.showPasswordNew })}
                          >
                            {this.state.showPasswordNew ? <RiEyeOffFill /> : <RiEyeFill />}
                          </button>
                        </div>
                        {this.state.errorslogin["newpassword"] ?
                          <div className="text-xs italic text-red-500 invalid-feedback">
                            {this.state.errorslogin["newpassword"]}
                          </div>
                          : null}
                      </div>
                      <div className="w-full mt-4">
                        <label className="block mb-1 text-sm font-medium tracking-wide text-[#0E1F51]" htmlFor="grid-first-name">Confirm New Password</label>
                        <div className="relative">
                          <input
                            className="block w-full px-4 py-3 leading-tight text-gray-700 text-base font-normal bg-[#F1F1F1] border border-gray-200 rounded-md appearance-none focus:outline-none focus:bg-white pr-12"
                            name="confirmnewpassword"
                            type={this.state.showPasswordConfirm ? "text" : "password"}
                            placeholder='Confirm New Password'
                            value={this.state.fieldsUser && this.state.fieldsUser["confirmnewpassword"] ? this.state.fieldsUser["confirmnewpassword"] : ''}
                            onChange={this.inputChange}
                          />
                          <button
                            className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500"
                            onClick={() => this.setState({ showPasswordConfirm: !this.state.showPasswordConfirm })}
                          >
                            {this.state.showPasswordConfirm ? <RiEyeOffFill /> : <RiEyeFill />}
                          </button>
                        </div>
                        {this.state.errorslogin["confirmnewpassword"] ?
                          <div className="text-xs italic text-red-500 invalid-feedback">
                            {this.state.errorslogin["confirmnewpassword"]}
                          </div>
                          : null}
                      </div>

                      <div className="block w-full pt-4 rounded-md shadow-sm">
                        <button className="flex justify-center w-full px-3 py-3 mx-auto text-sm text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md font-meduim all-btn-color focus:outline-none" type="button" onClick={this.updatePasswordSubmit}>Update Password</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user
  };
}
export default connect(mapStateToProps)(ChangePassword);
