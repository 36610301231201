import React from "react";
import ApexCharts from "react-apexcharts";

export default function ApexLineChart({ getDataforTaskCSuccess }) {

  const series = [{
    name: 'Today Income',
    data: getDataforTaskCSuccess&&getDataforTaskCSuccess.length>0&&getDataforTaskCSuccess.map(obj => ({
      x: new Date(obj.date),
      y: obj.count
    }))
  }];

  // Options for ApexCharts
  const options = {
    series: series,
    chart: {
      height: 350,
      type: 'line' // Set chart type to 'area'
    },
    xaxis: {
      type: 'datetime'
    },
    title: {
      text: 'Traffic Sources'
    },
    dataLabels: {
      enabled: true
    },
    colors: ['#A37927']
  };

  return (
    <ApexCharts
      options={options}
      series={series}
      type="line"
      height={350}
    />
  );
}



