import React, { Component } from "react";
import { alertActions, userActions } from "../../_actions";
import { connect } from "react-redux";
import moment from "moment";

class Counter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsImage: {},
      errorsImage: {},
      selectedImage: null,
      message: null,
      selectedImage2: null,
    };
  }

  componentDidMount() {
    let data2 = {
      type: "tree",
    };
    this.props.dispatch(userActions.getBptDetailsForUser(data2));
    window.scrollTo(0, 0)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("nextProps.users", nextProps.users.filesDetails);
    if (nextProps.users.filesDetails) {
      return {
        ...nextProps,

        imageName:
          nextProps.users &&
            nextProps.users.filesDetails &&
            nextProps.users.filesDetails.imageName
            ? nextProps.users.filesDetails.imageName
            : null,
        imageNameUrl:
          nextProps.users &&
            nextProps.users.filesDetails &&
            nextProps.users.filesDetails.imageURL
            ? nextProps.users.filesDetails.imageURL
            : null,
      };
    }
    if (nextProps.users.tempUserSuccess) {
      console.log("1111111111111111111111111", nextProps.users.tempUserSuccess);
      return {
        ...nextProps,

        fieldsprofile:
          nextProps.users && nextProps.users.Profile
            ? nextProps.users.Profile
            : null,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }
  inputImageChange = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      const reader = new FileReader();
      this.props.dispatch(userActions.uploadImage(e.target.files[0]));
      reader.onload = (e) => {
        const imageDataURL = e.target.result;
        this.setState({ selectedImage: imageDataURL });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  inputImageChange2 = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      const reader = new FileReader();
      this.props.dispatch(userActions.uploadImage(e.target.files[0]));
      reader.onload = (e) => {
        const imageDataURL = e.target.result;
        this.setState({ selectedImage2: imageDataURL });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  submitVoiceImageBpt = () => {
    let { users } = this.props;
    let { filesDetails } = users;
    let dataRes = {
      type: "tree",
    };

    let reqData = {
      type: "tree",
      image1:
        filesDetails && filesDetails.filesDetails.imageName
          ? filesDetails.filesDetails.imageName
          : this.state.fieldsImage.image1,
      message: this.state.message ? this.state.message : "null",
      name: "BPT_Plant A Tree",
    };
    this.props.dispatch(userActions.voiceBptImageUpload(reqData, dataRes));
  };

  treeBptImageUpload = () => {
    const { users } = this.props;
    const { filesDetails, getBptDetailsForUser } = users;
    const createTime = moment(getBptDetailsForUser.createdAt);
    const sixMonthAfter = createTime.add(14, "days");
    const todayDate = Date.now();

    console.log('todayDate >= sixMonthAfter.valueOf()', sixMonthAfter.valueOf());
    console.log('todayDate >= sixMonthAfter.valueOf()', todayDate >= sixMonthAfter.valueOf());
    console.log('todayDate >= sixMonthAfter.valueOf()', todayDate);

    if (todayDate >= sixMonthAfter.valueOf()) {
      const reqData = {
        image2: filesDetails && filesDetails.filesDetails.imageName
          ? filesDetails.filesDetails.imageName
          : this.state.fieldsImage.image2,
        name: "BPT_Plant A Tree Second",
      };
      let data2 = {
        type: "tree",
      };

      this.props.dispatch(userActions.treeBptImageUpload(reqData, data2));
    } else {
      this.props.dispatch(alertActions.error("You can complete this task after a 14 days Completion"));
    }
  };


  handelMsgOnChange = (event) => {
    console.log(event.target.value);
    this.setState({ message: event.target.value });
  };

  render() {
    let { users } = this.props;
    let { getBptDetailsForUser, getSocialMediaById, filesDetails, isSuccess } =
      users;
    console.log("isSuccessisSuccess1111111111", getBptDetailsForUser);
    return (
      <>
        <div className="flex flex-wrap mx-auto gap-4">
          {getBptDetailsForUser == null ? (
            < div className="px-6 py-5 space-y-4 bg-white rounded-xl">
              <h1 className="text-lg md:text-xl all-txt-color">
                Please upload proof of any plantation you have done and how you
                are taking care of it. This plantation should be new (started
                after subscribing to M7i Staking Plan) and will require 2
                pictures to be submitted.
              </h1>
              <div className="">
                <p className="text-[#0E1F51]">
                  Make sure the uploaded document contains:
                </p>
              </div>
              <div className="space-y-2">
                <div className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    className="w-4 h-4 accent-[#BA8A2C]"

                  />
                  <p className="text-sm text-[#0E1F51]">
                    One Picture taken at the time of Plantation.
                  </p>
                </div>
                <div className="flex items-center space-x-3">
                  <input type="checkbox" className="w-4 h-4 accent-[#BA8A2C]" />
                  <p className="text-sm text-[#0E1F51]">
                    {" "}
                    Another after 6 months of the same Plant.
                  </p>
                </div>
              </div>
              <p className="text-sm text-[#0E1F51]">
                {" "}
                Tree Bio Preservation Task Image First.
              </p>
              <div
                class="md:w-[400px] w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6"
                id="dropzone"
              >
                <input
                  type="file"
                  class="absolute inset-0 w-full h-full opacity-0 z-50"
                  onChange={this.inputImageChange}
                />
                <div class="text-center">
                  <img
                    class="mx-auto h-12 w-12"
                    src={
                      this.state.selectedImage ||
                      "https://www.svgrepo.com/show/357902/image-upload.svg"
                    }
                    alt=""
                  />

                  <h3 class="mt-2 text-sm font-medium text-gray-900">
                    <label for="file-upload" class="relative cursor-pointer">
                      <span className="text-[#0E1F51]">Drag and drop</span>
                      <span class="text-indigo-600"> or browse </span>
                      <span className="text-[#0E1F51]">to upload</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only cursor-pointer"
                      />
                    </label>
                  </h3>
                  <p class="mt-1 text-xs text-gray-500">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
                <img src="" class="mt-4 mx-auto max-h-40 hidden" id="preview" />
              </div>
              <p className="text-[#0E1F51]">
                Please use the box to type the name of the specie planted and
                where and when it was planted in the box below
              </p>

              <div className="relative md:w-[400px] w-ful">
                <label className="block w-full mb-1 font-light text-[#0E1F51]">
                  Message (optional)
                </label>
                <input
                  className="block w-full px-4 py-3 mx-auto mt-2 text-sm font-normal text[#0E1F51] transition duration-150 ease-in-out bg-[#F0F0F0] border border-gray-400 rounded-md sm:leading-5 border-opacity-20 focus:outline-none focus:bg-gray-100"
                  name="address2"
                  placeholder="Message"
                  value={this.state.message}
                  type="text"
                  onChange={this.handelMsgOnChange}
                />
              </div>
              <div className="mt-10 rounded-lg md:w-[400px] w-ful">
                <button
                  className="w-full cursor-pointer items-center block px-10 py-3.5 text-base font-medium text-center text-white transition duration-500 ease-in-out transform shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 all-btn-color uppercase"
                  onClick={this.submitVoiceImageBpt}
                >
                  submit
                </button>
              </div>
            </div>
          ) : getBptDetailsForUser !== null &&
            getBptDetailsForUser.imageUrl1 != "" &&
            getBptDetailsForUser.imageUrl2 == null ? (
            <div>
              <div className="px-6 py-5 space-y-4 bg-white rounded-xl">
                <h1 className="text-lg md:text-xl all-txt-color">
                  Please upload proof of any plantation you have done and how
                  you are taking care of it. This plantation should be new
                  (started after subscribing to M7i Staking Plan) and will
                  require 2 pictures to be submitted.
                </h1>
                <div className="">
                  <p className="text-[#0E1F51]">
                    Make sure the uploaded document contains:
                  </p>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      className="w-4 h-4 accent-[#BA8A2C]"

                    />
                    <p className="text-sm text-[#0E1F51]">
                      One Picture taken at the time of Plantation.
                    </p>
                  </div>
                  <div className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      className="w-4 h-4 accent-[#BA8A2C]"
                    />
                    <p className="text-sm text-[#0E1F51]">
                      {" "}
                      Another after 6 months of the same Plant.
                    </p>
                  </div>
                </div>
                <p className="text-sm text-[#0E1F51]">
                  {" "}
                  Tree Bio Preservation Task Image Second.
                </p>
                <div
                  class="md:w-[400px] w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6"
                  id="dropzone"
                >
                  <input
                    type="file"
                    class="absolute inset-0 w-full h-full opacity-0 z-50"
                    onChange={this.inputImageChange2}
                  />
                  <div class="text-center">
                    <img
                      class="mx-auto h-12 w-12"
                      src={
                        this.state.selectedImage2 ||
                        "https://www.svgrepo.com/show/357902/image-upload.svg"
                      }
                      alt=""
                    />

                    <h3 class="mt-2 text-sm font-medium text-gray-900">
                      <label for="file-upload" class="relative cursor-pointer">
                        <span className="text-[#0E1F51]">Drag and drop</span>
                        <span class="text-indigo-600"> or browse </span>
                        <span className="text-[#0E1F51]">to upload</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          class="sr-only cursor-pointer"
                        />
                      </label>
                    </h3>
                    <p class="mt-1 text-xs text-gray-500">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                  <img
                    src=""
                    class="mt-4 mx-auto max-h-40 hidden"
                    id="preview"
                  />
                </div>
                <p className="text-[#0E1F51]">
                  Please use the box to type the name of the specie planted and
                  where and when it was planted in the box below
                </p>

                <div className="relative md:w-[400px] w-ful">
                  <label className="block w-full mb-1 font-light text-[#0E1F51]">
                    Message (optional)
                  </label>
                  <input
                    className="block w-full px-4 py-3 mx-auto mt-2 text-sm font-normal text[#0E1F51] transition duration-150 ease-in-out bg-[#F0F0F0] border border-gray-400 rounded-md sm:leading-5 border-opacity-20 focus:outline-none focus:bg-gray-100"
                    name="address2"
                    placeholder="Message"
                    value={this.state.message}
                    type="text"
                    onChange={this.handelMsgOnChange}
                  />
                </div>
                <div className="mt-10 rounded-lg md:w-[400px] w-ful">
                  <button
                    className="w-full cursor-pointer items-center block px-10 py-3.5 text-base font-medium text-center text-white transition duration-500 ease-in-out transform shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 all-btn-color uppercase"
                    onClick={this.treeBptImageUpload}
                  >
                    submit
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>

              {getBptDetailsForUser && getBptDetailsForUser.status && getBptDetailsForUser.status == 1 ?
                < div className="px-6 py-5 space-y-4 bg-white rounded-xl text-green-500">
                  Approved (Plant A Tree)
                </div>

                :
                getBptDetailsForUser && getBptDetailsForUser.status && getBptDetailsForUser.status == 2 ?




                  <>
                    {getBptDetailsForUser && getBptDetailsForUser.status && getBptDetailsForUser.status == 2 && getBptDetailsForUser && getBptDetailsForUser.isSubmit == true ?
                      < div className="px-6 py-5 space-y-4 bg-white rounded-xl">
                        <h1 className='text-xs md:text-sm text-center text-red-500'>Your document has been rejected. Please re-upload your document.</h1>
                        <h1 className="text-lg md:text-xl all-txt-color">
                          Please upload proof of any plantation you have done and how you
                          are taking care of it. This plantation should be new (started
                          after subscribing to M7i Staking Plan) and will require 2
                          pictures to be submitted.
                        </h1>
                        <div className="">
                          <p className="text-[#0E1F51]">
                            Make sure the uploaded document contains:
                          </p>
                        </div>
                        <div className="space-y-2">
                          <div className="flex items-center space-x-3">
                            <input
                              type="checkbox"
                              className="w-4 h-4 accent-[#BA8A2C]"

                            />
                            <p className="text-sm text-[#0E1F51]">
                              One Picture taken at the time of Plantation.
                            </p>
                          </div>
                          <div className="flex items-center space-x-3">
                            <input type="checkbox" className="w-4 h-4 accent-[#BA8A2C]" />
                            <p className="text-sm text-[#0E1F51]">
                              {" "}
                              Another after 6 months of the same Plant.
                            </p>
                          </div>
                        </div>
                        <p className="text-sm text-[#0E1F51]">
                          {" "}
                          Tree Bio Preservation Task Image First.
                        </p>
                        <div
                          class="md:w-[400px] w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6"
                          id="dropzone"
                        >
                          <input
                            type="file"
                            class="absolute inset-0 w-full h-full opacity-0 z-50"
                            onChange={this.inputImageChange}
                          />
                          <div class="text-center">
                            <img
                              class="mx-auto h-12 w-12"
                              src={
                                this.state.selectedImage ||
                                "https://www.svgrepo.com/show/357902/image-upload.svg"
                              }
                              alt=""
                            />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">
                              <label for="file-upload" class="relative cursor-pointer">
                                <span className="text-[#0E1F51]">Drag and drop</span>
                                <span class="text-indigo-600"> or browse </span>
                                <span className="text-[#0E1F51]">to upload</span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  class="sr-only cursor-pointer"
                                />
                              </label>
                            </h3>
                            <p class="mt-1 text-xs text-gray-500">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                          <img src="" class="mt-4 mx-auto max-h-40 hidden" id="preview" />
                        </div>
                        <p className="text-[#0E1F51]">
                          Please use the box to type the name of the specie planted and
                          where and when it was planted in the box below
                        </p>

                        <div className="relative md:w-[400px] w-ful">
                          <label className="block w-full mb-1 font-light text-[#0E1F51]">
                            Message (optional)
                          </label>
                          <input
                            className="block w-full px-4 py-3 mx-auto mt-2 text-sm font-normal text[#0E1F51] transition duration-150 ease-in-out bg-[#F0F0F0] border border-gray-400 rounded-md sm:leading-5 border-opacity-20 focus:outline-none focus:bg-gray-100"
                            name="address2"
                            placeholder="Message"
                            value={this.state.message}
                            type="text"
                            onChange={this.handelMsgOnChange}
                          />
                        </div>
                        <div className="mt-10 rounded-lg md:w-[400px] w-ful">
                          <button
                            className="w-full cursor-pointer items-center block px-10 py-3.5 text-base font-medium text-center text-white transition duration-500 ease-in-out transform shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 all-btn-color uppercase"
                            onClick={this.submitVoiceImageBpt}
                          >
                            submit
                          </button>
                        </div>
                      </div>
                      :
                      null}
                  </>



                  :
                  <>
                    {getBptDetailsForUser.status == "0" && getBptDetailsForUser.isSubmit == true ?
                      < div className="px-6 py-5 space-y-4 bg-white rounded-xl">
                        Pending (Plant A Tree)
                      </div>
                      :
                      <div>
                        <div className="px-6 py-5 space-y-4 bg-white rounded-xl">
                          <h1 className='text-xs md:text-sm text-center text-red-500'>Your document has been rejected. Please re-upload your document.</h1>
                          <h1 className="text-lg md:text-xl all-txt-color">
                            Please upload proof of any plantation you have done and how
                            you are taking care of it. This plantation should be new
                            (started after subscribing to M7i Staking Plan) and will
                            require 2 pictures to be submitted.
                          </h1>
                          <div className="">
                            <p className="text-[#0E1F51]">
                              Make sure the uploaded document contains:
                            </p>
                          </div>
                          <div className="space-y-2">
                            <div className="flex items-center space-x-3">
                              <input
                                type="checkbox"
                                className="w-4 h-4 accent-[#BA8A2C]"

                              />
                              <p className="text-sm text-[#0E1F51]">
                                One Picture taken at the time of Plantation.
                              </p>
                            </div>
                            <div className="flex items-center space-x-3">
                              <input
                                type="checkbox"
                                className="w-4 h-4 accent-[#BA8A2C]"
                              />
                              <p className="text-sm text-[#0E1F51]">
                                {" "}
                                Another after 6 months of the same Plant.
                              </p>
                            </div>
                          </div>
                          <p className="text-sm text-[#0E1F51]">
                            {" "}
                            Tree Bio Preservation Task Image Second.
                          </p>
                          <div
                            class="md:w-[400px] w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6"
                            id="dropzone"
                          >
                            <input
                              type="file"
                              class="absolute inset-0 w-full h-full opacity-0 z-50"
                              onChange={this.inputImageChange2}
                            />
                            <div class="text-center">
                              <img
                                class="mx-auto h-12 w-12"
                                src={
                                  this.state.selectedImage2 ||
                                  "https://www.svgrepo.com/show/357902/image-upload.svg"
                                }
                                alt=""
                              />

                              <h3 class="mt-2 text-sm font-medium text-gray-900">
                                <label for="file-upload" class="relative cursor-pointer">
                                  <span className="text-[#0E1F51]">Drag and drop</span>
                                  <span class="text-indigo-600"> or browse </span>
                                  <span className="text-[#0E1F51]">to upload</span>
                                  <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    class="sr-only cursor-pointer"
                                  />
                                </label>
                              </h3>
                              <p class="mt-1 text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </div>
                            <img
                              src=""
                              class="mt-4 mx-auto max-h-40 hidden"
                              id="preview"
                            />
                          </div>
                          <p className="text-[#0E1F51]">
                            Please use the box to type the name of the specie planted and
                            where and when it was planted in the box below
                          </p>

                          <div className="relative md:w-[400px] w-ful">
                            <label className="block w-full mb-1 font-light text-[#0E1F51]">
                              Message (optional)
                            </label>
                            <input
                              className="block w-full px-4 py-3 mx-auto mt-2 text-sm font-normal text[#0E1F51] transition duration-150 ease-in-out bg-[#F0F0F0] border border-gray-400 rounded-md sm:leading-5 border-opacity-20 focus:outline-none focus:bg-gray-100"
                              name="address2"
                              placeholder="Message"
                              value={this.state.message}
                              type="text"
                              onChange={this.handelMsgOnChange}
                            />
                          </div>
                          <div className="mt-10 rounded-lg md:w-[400px] w-ful">
                            <button
                              className="w-full cursor-pointer items-center block px-10 py-3.5 text-base font-medium text-center text-white transition duration-500 ease-in-out transform shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 all-btn-color uppercase"
                              onClick={this.treeBptImageUpload}
                            >
                              submit
                            </button>
                          </div>
                        </div>
                      </div>}
                  </>

              }



              {/* Successfully Both task has been Completed */}
            </>
          )}
        </div >
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview, getSocialMediaById } = users ? users : {};
  const { user } = overview ? overview : {};
  return {
    users,
    user,
    getSocialMediaById,
  };
}

export default connect(mapStateToProps)(Counter);
