import React, { Component } from "react";
import { dashboardActions, userActions, alertActions } from "../../_actions";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import "jspdf-autotable";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { PiDownloadSimpleBold, PiAddressBookThin } from "react-icons/pi";
import { FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { MdOutlineLocalAirport } from "react-icons/md";
import { RiUserVoiceFill } from "react-icons/ri";
import { confirmAlert } from "react-confirm-alert";
import ApexLineChart from "../charts/components/ApexLineChart.js";
import TabOne from "../../components/DashboardTab/TabOne";
import TabTwo from "../../components/DashboardTab/TabTwo";
import TabThree from "../../components/DashboardTab/TabThree";
import TabFour from "../../components/DashboardTab/TabFour";
import TabFive from "../../components/DashboardTab/TabFive";
import TabSix from "../../components/DashboardTab/TabSix";
import TabSeven from "../../components/DashboardTab/TabSeven";
import QRCode from "qrcode.react";
import { BiCopy } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { CONST } from "../../_config/index";
import { Link } from 'react-router-dom';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      fieldsCoin: {},
      errorsCoin: {},
      fieldslogin: {},
      errorslogin: {},
      option: {},
      coinamountError: '',
      showLeaderSection: false,
      activeTab: 1,
      isPackageListOpen: false,
      isBioPreservation: false,
      isCertificates: false,
      isMakeGift: false,
      isHovered: false,
      elementData: {},
      currencyType: null,
      fieldsIndex: {},
      depositIndexCreateModal: false,
      errorsIndex: {},
      coinData: {},
      coinBalance: false,
      coinRewardBalance: false,
      coinamount: '',
      dropdownVisible: false
    };
  }

  componentDidMount() {
    let data2 = {
      keyWord: "",
      pageNo: 1,
      size: 10,
    };
    this.props.dispatch(userActions.getProfileDataPi());
    // this.props.dispatch(userActions.getBptDetailsForUser());


    this.props.dispatch(userActions.graphDataForTaskComplete());



    this.props.dispatch(userActions.getPackageList(data2));
    // this.props.dispatch(userActions.upDateAction());
    this.props.dispatch(userActions.getCoinByName({ coinName: "M7i" }));
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.sendCoinSuccess) {
      return {
        ...nextProps,
        fieldslogin: {},
        errorslogin: {},
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  handleBuyPackage = (data) => {
    let datatemp = {
      packageId: data.id,
      amount: data.price,
    };
    confirmAlert({
      title: "Confirm to buy package?",
      p: `Are you sure to spend ${data.price} $?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.dispatch(dashboardActions.buyPackage(datatemp));
            // this.props.dispatch(userActions.getProfileDataPi())
          },
        },
        {
          label: "No",
        },
      ],
    });
  };


  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleOpenPackageList = () => {
    this.setState({ isPackageListOpen: true });
  };

  handleClosePackageList = () => {
    this.setState({ isPackageListOpen: false });
  };

  togglePackageList = () => {
    this.setState((prevState) => ({
      isPackageListOpen: !prevState.isPackageListOpen,
    }));
  };

  handleOpenDepositIndexModal = (data) => {
    this.setState({ depositIndexCreateModal: true });
  };
  handleCloseDepositIndexModal = () => {
    this.setState({ depositIndexCreateModal: false });
  };

  copyCodeToClipboard = (data) => {
    navigator.clipboard.writeText(data)
    this.props.dispatch(alertActions.success("Copied!"));
  }

  handleOpencommingSoonModal = (data) => {
    this.setState({ elementData: data });
    let resData = {
      coinId: data.id,
      coinType: data.ticker,
    };
    let temp = {
      keyWord: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    };
    this.props.dispatch(userActions.getAddressDepositTime(resData, temp));

    let { users } = this.props;
    let { addressDepositTime } = users;
    if (addressDepositTime && addressDepositTime.address) {

      this.setState({ addressDepositTime })
      this.setState({ coinData: data })
      this.handleOpenDepositIndexModal();
    }
  };
  // handleSubmitPayment = async (element) => {

  //   this.setState({ loading: true, paymentError: null });

  //   // Send the token to your server for payment processing
  //   fetch(`${CONST.BACKEND_URL}/payPalGateway`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization:
  //         "Bearer " + JSON.parse(sessionStorage.getItem("user")).token,
  //     },
  //     body: JSON.stringify({
  //       amount: element && element.price ? element.price : null,
  //       return_url: window.location.href.replace("dashboard", "") + `${element.price}` + `/${element.id}` + "/success-package",
  //       cancel_url: window.location.href,
  //       description: "BUY_PACKAGE",
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       window.location.replace(data);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  // };
  inputChangeAmount = (event) => {
    event.preventDefault();
    let { users } = this.props;
    let { overview } = users;
    let { setting } = overview;
    let { txFee } = setting ? setting : {};
    console.log("event.target.value  ", event.target.value);
    if (event.target.value >= 0) {
      const { fieldsIndex, errorsIndex } = this.state;
      fieldsIndex[event.target.name] = event.target.value;
      fieldsIndex["amount"] = event.target.value;
      fieldsIndex["fees"] = txFee ? txFee : 0.0001;
      fieldsIndex["recievingAmount"] = parseFloat(
        event.target.value - (txFee ? txFee : 0.0001)
      ).toFixed(8);
      errorsIndex["amount"] = "";
      this.setState({ fieldsIndex, errorsIndex });
    } else {
      const { errorsIndex } = this.state;
      errorsIndex["amount"] = "Amount should be positive number.";
      this.setState({ errorsIndex });
    }
  };

  inputChange = (event) => {
    // console.log("event________________________", event.target.value);
    event.preventDefault();
    const { fieldsIndex, errorsIndex } = this.state;
    fieldsIndex[event.target.name] = event.target.value;
    errorsIndex[event.target.name] = "";
    // console.log(event.target.name, event.target.value);
    this.setState({ fieldsIndex, errorsIndex });
  };

  copyTxIdToClipboard = (txId) => {
    const tempInput = document.createElement("input");
    tempInput.value = txId;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.setState({ copiedTxId: txId });
    this.props.dispatch(alertActions.success("Copied!"));
  };
  toggleBioPreservation = () => {
    let { users } = this.props
    let { piItem } = users
    console.log('piItem_________________________', piItem);
    let balanceData = piItem && piItem.wallet && piItem.wallet.balance ? piItem.wallet.balance : 0
    let isBpt = piItem && piItem.user && piItem.user.isBpt && piItem.user.isBpt == true ? true : false
    console.log('isBpt___________________', isBpt);
    if (balanceData != 999 && isBpt == false) {
      confirmAlert({
        title: `Dear User, Buy ${(999 - balanceData)} more M7i to unlock Bio Presevation Tasks`,
        // p: `Buy ${(999 - balanceData)} more M7i to unlock Bio Presevation Tasks`,
        buttons: [
          {
            label: "ok"
          }
        ]
      });
    } else {
      this.setState((prevState) => ({
        isBioPreservation: !prevState.isBioPreservation,
      }));
    }
  };
  toggleCertificates = () => {
    this.setState((prevState) => ({
      isCertificates: !prevState.isCertificates,
    }));
  };
  toggleMakeGift = () => {
    this.setState((prevState) => ({
      isMakeGift: !prevState.isMakeGift,
    }));
  };

  toggleHover = () => {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
    }));
  };

  validationPaymentCheck = () => {
    let coinamount = this.state.coinamount
    let coinamountError = ""
    let formIsValid = true;

    if (coinamount <= 0) {
      formIsValid = false;
      coinamountError = "Amount must be greater than 0";
    }

    if (!coinamount || coinamount === "") {
      formIsValid = false;
      coinamountError = "Cannot be empty";
    }

    this.setState({ coinamountError: coinamountError });
    return formIsValid;
  }

  handleSubmitPayment = async () => {
    // e.preventDefault();
    if (this.validationPaymentCheck()) {

      this.setState({ loading: true, paymentError: null });

      fetch(`${CONST.BACKEND_URL}/payPalGateway`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          amount: this.state.coinamount,
          return_url:
            window.location.href.replace("dashboard", "") + `${this.state.coinamount}` + "/success",
          cancel_url: window.location.href,
          description: "CONTRIBUTION",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          window.location.replace(data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  handelOnchangeInput = (event) => {
    console.log('event___________________', event.target.value);
    if (event.target.value == "00" || event.target.value > 9999) {
    } else {
      this.setState({
        coinamount: event.target.value,
      });
    }
  };



  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownVisible: !prevState.dropdownVisible
    }));
  };

  inputCoinChange = (e) => {
    e.preventDefault();
    console.log('e_________________________', e.target.value);
    let { name, value } = e.target;
    let fieldsCoin = this.state.fieldsCoin;
    let errorsCoin = this.state.errorsCoin;
    fieldsCoin[name] = value;
    console.log(name, value);
    errorsCoin[name] = "";
    this.setState({ fieldsCoin, errorsCoin });
  }
  applyClimateCertificateRequestSubmit = () => {
    if (this.ValidationHandel()) {
      let resData = {
        is_Apply: true,
        type: this.state.fieldsCoin && this.state.fieldsCoin['type'] ? this.state.fieldsCoin['type'] : null
      }
      this.props.dispatch(userActions.applyClimateCertificateRequest(resData));
    }
  }
  ValidationHandel = () => {
    let fieldsCoin = this.state.fieldsCoin
    let errorsCoin = {}
    let validate = true
    if (!fieldsCoin['type'] || fieldsCoin['type'] === "") {
      errorsCoin['type'] = `Can't be empty type`
      validate = false
    }
    console.log('errorsCoin', errorsCoin);
    this.setState({ errorsCoin: errorsCoin })
    return validate
  }

  render() {
    let { users } = this.props;
    let { loading, coinName, piItem ,getDataforTaskCSuccess} = users;
    console.log('coinBalancecoinBalance', piItem);


    let userBalance = piItem && piItem.bptCount ? piItem.bptCount : 0
  
  
    const {
      isPackageListOpen,
      isBioPreservation,
      isCertificates,
      isMakeGift,
      activeTab,
      isHovered, dropdownVisible
    } = this.state;


    // const { dropdownVisible } = this.state;
    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <div className="px-6 py-6">
            <div className="flex items-center justify-end space-x-4">
              {/* <div className="flex flex-col" data-tooltip-id="my-tooltip-3">
                <button
                  className="flex items-center p-3 text-base font-bold text-center text-white cursor-pointer rounded-xl all-btn-color"
                  onClick={() => this.handleOpencommingSoonModal(coinName.data)}
                >
                  <span>
                    <PiAddressBookThin className=" w-10 h-10 " />
                  </span>
                </button>
              </div>
              <div className="flex flex-col" data-tooltip-id="my-tooltip-1">
                <a target="_blank" href="pdf/M7iWhitepaper.pdf">
                  <span className="flex items-center p-3 text-base font-bold text-center text-white cursor-pointer rounded-xl all-btn-color">
                    <img
                      src="/assets/whitepaper.png"
                      alt="whitepaper.png"
                      className="w-10 h-10"
                    />
                  </span>
                </a>
              </div> */}
              {/* <div className="flex flex-col" data-tooltip-id="my-tooltip-2">
                <span className="flex items-center p-3 text-base font-bold text-center text-white cursor-pointer rounded-xl all-btn-color">
                  <PiDownloadSimpleBold className="w-10 h-10" />
                </span>
              </div> */}

            </div>
        
              <div className="dropdown-content flex justify-end  space-x-2 ">
                <a target="_blank" href="https://apps.apple.com/">
                  <button className="flex items-center py-4 px-6 text-base font-bold text-center text-white cursor-pointer rounded-xl all-btn-color">
                    <FaAppStoreIos size={24} className="mr-2" /> <span>App Store</span>
                  </button>
                </a>
                <a target="_blank" href="https://play.google.com/">
                  <button className="flex items-center py-4 px-6  text-base font-bold text-center text-white cursor-pointer rounded-xl all-btn-color">
                    <FaGooglePlay size={24} className="mr-2" />  <span>Play Store</span>
                  </button>
                </a>
              </div>
          
            {/* <a></a> */}
            {/* <ReactTooltip
              id="my-tooltip-1"
              place="bottom"
              content="M7i Whitepaper"
            /> */}
            <ReactTooltip
              id="my-tooltip-2"
              place="bottom"
              content="Download M7i Wallet IOS/Android"
            />
            {/* <ReactTooltip
              id="my-tooltip-3"
              place="bottom"
              content="Coin Address"
            /> */}

            <section className="w-full mx-auto mt-4">
              <div className="px-5 py-4 dashboard-card rounded-xl">
                <div className="flex flex-wrap items-center justify-between">
                  <div className="flex flex-row items-center ">
                    <span className="relative flex items-center justify-center w-10 h-10 text-center md:w-16 md:h-16 wallet-bg">
                      <img
                        src="/assets/BioPreservation.png"
                        alt="wallet.png"
                        className="w-10 h-10"
                      />
                    </span>
                    <span className="pl-4 capitalize  whitespace-nowrap text-base font-bold md:text-2xl all-txt-color">
                    BPT task Count
                    </span>
                  </div>
                  <div className="flex items-center space-x-4 lg:pt-0 pt-2">
                    <div className="flex flex-col">
                      {this.state.coinBalance === true ? <span onClick={(e) => this.setState({ coinBalance: !this.state.coinBalance })} className="px-14 py-4 text-sm font-bold text-center text-white cursor-pointer rounded-xl bg-gradient-to-t from-[#47391e] to-[#cc9b3a]" >
                        {userBalance}
                      </span> : <span className="px-3 capitalize  whitespace-nowrap py-4 text-sm font-bold text-center text-white cursor-pointer rounded-xl all-btn-color" onClick={(e) => this.setState({ coinBalance: !this.state.coinBalance })} >
                        Show task Count
                      </span>}

                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="flex flex-col w-full gap-10 mx-auto my-6 cursor-pointer lg:flex-row"
              onClick={this.toggleBioPreservation}
            >
              <div className="w-full space-y-4">
                <div className="flex items-center justify-between px-5 py-4 dashboard-card rounded-xl">
                  <div className="flex flex-row items-center ">
                    <span className="relative flex items-center justify-center w-10 h-10 text-center md:w-16 md:h-16 wallet-bg">
                      <img
                        src="/assets/BioPreservation.png"
                        alt="BioPreservation.png"
                        className="w-10 h-10"
                      />
                    </span>
                    <span className="pl-4 text-base font-bold md:text-2xl all-txt-color">
                      Bio Preservation Tasks
                    </span>
                  </div>
                  <span className="cursor-pointer all-txt-color">
                    {!isBioPreservation ? (
                      <IoIosArrowDown className="w-7 h-7" />
                    ) : (
                      <IoIosArrowUp className="w-7 h-7" />
                    )}
                  </span>
                </div>
              </div>
            </section>


            {isBioPreservation && (<>
              {/* {getBptDetailsForUser && getBptDetailsForUser.type && getBptDetailsForUser.type == "voice" ?
                <>
                  {getBptDetailsForUser && getBptDetailsForUser.status && getBptDetailsForUser.status == 1 ?
                    < div className="bg-white p-5 flex justify-center items-center text-lg font-bold text-green-700 shadow-lg rounded-xl">
                      Admin approved your task.
                    </div>
                    :
                    getBptDetailsForUser && getBptDetailsForUser.status && getBptDetailsForUser.status == 2 ?
                      < div className="bg-white p-5 flex justify-center items-center text-lg font-bold text-red-700 shadow-lg rounded-xl">
                        Admin rejceted your task.
                      </div>
                      :
                      < div className="bg-white p-5 flex justify-center items-center text-lg font-bold text-blue-700 shadow-lg rounded-xl">
                        Successfully upload task. Please wait for admin approval!
                      </div>

                  }
                </>

                : */}
              <>
                <p className="text-lg text-[#0E1F51]">
                  Please use this section to upload evidence for the completion
                  of the Bio Preservation Task. Only 1 task is to be completed.
                </p>
                <div className="flex flex-col items-start w-full gap-10 py-6 xl:flex-row ">
                  <div className="hidden w-full capitalize bg-gray-200 rounded-lg md:block lg:w-80">
                    <div
                      onClick={() => this.handleTabChange(1)}
                      className={
                        this.state.activeTab == 1
                          ? "active active all-btn-color rounded-lg shadow text-white p-4 w-full lg:w-80"
                          : "bg-[#fff]/10 all-txt-color p-4 rounded cursor-pointer"
                      }
                    >
                      <div className="flex items-center space-x-3">
                        {/* <img
                          src={
                            activeTab === 1
                              ? '/assets/voicewhite.png'
                              : isHovered
                                ? '/assets/voicewhite.png'
                                : '/assets/voice.png'
                          }
                          alt="voice.png"
                          className="w-10 h-10"
                        /> */}
                        <RiUserVoiceFill className="w-8 h-8 mr-2" />
                        <span>1. Voice your Opinion</span>
                      </div>
                    </div>

                    <div
                      onClick={() => this.handleTabChange(2)}
                      className={
                        this.state.activeTab == 2
                          ? "active active all-btn-color rounded-lg shadow text-white p-4 w-full lg:w-80"
                          : "bg-[#fff]/10 all-txt-color p-4 rounded cursor-pointer"
                      }
                    >
                      <div className="flex items-center space-x-3">
                        <img
                          src={
                            activeTab === 2
                              ? "/assets/changewhite.png"
                              : isHovered
                                ? "/assets/changewhite.png"
                                : "/assets/change.png"
                          }
                          alt="change.png"
                          className="w-8 h-8 mr-2"
                        />
                        {/* <RiFileListFill className="w-8 h-8 mr-2" /> */}
                        <span>2. Climate Change Research</span>
                      </div>
                    </div>
                    <div
                      onClick={() => this.handleTabChange(3)}
                      className={
                        this.state.activeTab == 3
                          ? "active active all-btn-color rounded-lg shadow text-white p-4 w-full lg:w-80"
                          : "bg-[#fff]/10 all-txt-color p-4 rounded cursor-pointer"
                      }
                    >
                      <div className="flex items-center space-x-3">
                        <img
                          src={
                            activeTab === 3
                              ? "/assets/tree.png"
                              : isHovered
                                ? "/assets/tree.png"
                                : "/assets/tree.png"
                          }
                          alt="tree.png"
                          className="w-10 h-10"
                        />
                        {/* <GiBonsaiTree className="mr-1 w-9 h-9" /> */}
                        <span>3. Plant a Tree</span>
                      </div>
                    </div>

                    <div
                      onClick={() => this.handleTabChange(4)}
                      className={
                        this.state.activeTab == 4
                          ? "active active all-btn-color rounded-lg shadow text-white p-4 w-full lg:w-80"
                          : "bg-[#fff]/10 all-txt-color p-4 rounded cursor-pointer"
                      }
                    >
                      <div className="flex items-center space-x-3">
                        <img
                          src={
                            activeTab === 4
                              ? "/assets/lightwhite.png"
                              : isHovered
                                ? "/assets/lightewhite.png"
                                : "/assets/light.png"
                          }
                          alt="light.png"
                          className="w-6 ml-1 mr-2 h-9"
                        />
                        <span>4. Energy Efficient Lighting</span>
                      </div>
                    </div>

                    <div
                      onClick={() => this.handleTabChange(5)}
                      className={
                        this.state.activeTab == 5
                          ? "active active all-btn-color rounded-lg shadow text-white p-4 w-full lg:w-80"
                          : "bg-[#fff]/10 all-txt-color p-4 rounded cursor-pointer"
                      }
                    >
                      <div className="flex items-center space-x-3">
                        <MdOutlineLocalAirport className="w-8 h-8 mr-2" />
                        {/* <img
                          src={
                            activeTab === 5
                              ? '/assets/flywhite.png'
                              : isHovered
                                ? '/assets/flywhite.png'
                                : '/assets/fly.png'
                          }
                          alt="fly.png"
                          className="w-10 h-12"
                        /> */}
                        <span>5. Fly with Economy</span>
                      </div>
                    </div>

                    <div
                      onClick={() => this.handleTabChange(6)}
                      className={
                        this.state.activeTab == 6
                          ? "active active all-btn-color rounded-lg shadow text-white p-4 w-full lg:w-80"
                          : "bg-[#fff]/10 all-txt-color p-4 rounded cursor-pointer"
                      }
                    >
                      <div className="flex items-center space-x-3">
                        <img
                          src={
                            activeTab === 6
                              ? "/assets/cyclewhite.png"
                              : isHovered
                                ? "/assets/cyclewhite.png"
                                : "/assets/cycle.png"
                          }
                          alt="cycle.png"
                          className="w-10 h-10"
                        />
                        <span>6. Carbon Free Commutes</span>
                      </div>
                    </div>

                    <div
                      onClick={() => this.handleTabChange(7)}
                      className={
                        this.state.activeTab == 7
                          ? "active active all-btn-color rounded-lg shadow text-white p-4 w-full lg:w-80"
                          : "bg-[#fff]/10 all-txt-color p-4 rounded cursor-pointer"
                      }
                    >
                      <div className="flex items-center space-x-3">
                        <img
                          src={
                            activeTab === 7
                              ? "/assets/carwhite.png"
                              : isHovered
                                ? "/assets/carwhite.png"
                                : "/assets/car.png"
                          }
                          alt="car.png"
                          className="w-10 h-10"
                        />
                        <span>7. Drive Electric Automobile</span>
                      </div>
                    </div>
                  </div>

                  <div className="block w-full md:hidden">
                    <label
                      for="countries"
                      className="block mb-2 text-sm font-medium text-[#3D405B]"
                    >
                      Select an option
                    </label>
                    <select
                      id="dropdown"
                      onChange={this.handleDropdownChange}
                      value={activeTab}
                      class="bg-white border border-gray-300 text-[#3D405B] text-sm rounded-lg focus:ring-[#2196F3] focus:border-[#2196F3] block w-full p-2.5"
                    >
                      <option value="2">Profile Summary</option>
                      <option value="3">My Orders</option>
                      <option value="4">Assessment Code</option>
                    </select>
                  </div>

                  {this.state.activeTab === 1 ? <TabOne /> : null}

                  {this.state.activeTab === 2 ? (
                    <>
                      <TabTwo />
                    </>
                  ) : null}
                  {this.state.activeTab === 3 ? (
                    <>
                      <TabThree />
                    </>
                  ) : null}
                  {this.state.activeTab === 4 ? (
                    <>
                      <TabFour />
                    </>
                  ) : null}
                  {this.state.activeTab === 5 ? (
                    <>
                      <TabFive />
                    </>
                  ) : null}
                  {this.state.activeTab === 6 ? (
                    <>
                      <TabSix />
                    </>
                  ) : null}
                  {this.state.activeTab === 7 ? (
                    <>
                      <TabSeven />
                    </>
                  ) : null}
                </div>
              </>
              {/* } */}
            </>
            )}


            <section
              className="flex flex-col w-full gap-10 mx-auto my-6 cursor-pointer lg:flex-row"
              onClick={this.toggleCertificates}
            >
              <div className="w-full space-y-4">
                <div className="flex items-center justify-between px-5 py-4 dashboard-card rounded-xl">
                  <div className="flex flex-row items-center ">
                    <span className="relative flex items-center justify-center w-10 h-10 text-center md:w-16 md:h-16 wallet-bg">
                      <img
                        src="/assets/certificate.png"
                        alt="certificate.png"
                        className="w-10 h-10"
                      />
                    </span>
                    <span className="pl-4 text-base font-bold md:text-2xl all-txt-color">
                      Climate Hero Certificates
                    </span>
                  </div>
                  <span className="cursor-pointer all-txt-color">
                    {isCertificates ? (
                      <IoIosArrowUp className="w-7 h-7" />
                    ) : (
                      <IoIosArrowDown className="w-7 h-7" />
                    )}
                  </span>
                </div>
              </div>
            </section>

            {isCertificates && (
              <div className="px-6 py-5 space-y-3 bg-white rounded-xl">
                <p className="all-txt-color">
                  Bruce Wayne Enterprises Corporation will issue Certificate of
                  Recognition to our community heroes (Stakers and Non Stakers)
                  who will complete the Bio Preservation Task.
                </p>
                <p className="all-txt-color">
                  This certificate is a record of your individual action towards
                  Climate Change, officially recognised by B.W.E.C and signed by
                  the CEO.
                </p>
                <p className="all-txt-color">
                  Please come back here and hit the request button below once
                  your Bio Preservation Task has been completed.
                </p>
                {/* <p className="all-txt-color">Climate Hero Certificates are issued by Bruce Wayne Enterprises Corporation to the participants of the Bio Preservation Campaign. Please come back here to submit a request for your recognition once you have successfully completed the task.</p> */}

                <div className="w-full">
                  <label className="block mb-1 text-sm font-bold tracking-wide text-[#314498Iss]" for="grid-first-name">BPT Type</label>

                  <select className="block w-md px-4 py-2 leading-tight text-gray-700 bg-[#F1F1F1] border border-[#F1F1F1] rounded appearance-none focus:outline-none focus:bg-white" name="type" type="text" onClick={this.inputCoinChange}>
                    <option value=""
                    >Please Choose BPT Type &hellip;</option>
                    <option value="voice"> Voice Your Opinion</option>
                    <option value="climate">Climate Change Research</option>
                    <option value="tree">Plant A Tree</option>
                    <option value="energy">Energy Efficient Lighting</option>
                    <option value="fly">Fly With Economy</option>
                    <option value="carbon">Carbon Free Commutes</option>
                    <option value="drive">Drive Electric Automobile</option>
                    <option value="hero">Super Hero</option>
                  </select>
                  {this.state.errorsCoin["type"] ?
                    <div className="text-xs italic text-red-500 invalid-feedback capitalize">
                      {this.state.errorsCoin["type"]}
                    </div>
                    : null}
                </div>

                <div className="rounded-lg py-3 md:w-[500px] w-ful">
                  <div className="w-full cursor-pointer items-center block md:px-10 py-3.5 text-base font-medium text-center text-white transition duration-500 ease-in-out transform shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 all-btn-color uppercase" onClick={() => this.applyClimateCertificateRequestSubmit()}>
                    Request Climate Hero Certificate
                  </div>
                </div>
                <div className="flex  space-x-2 text-gray-600">
                  <p>Note:</p>
                  <p className="font-light">
                    (Please note at this moment BWEC will only issue
                    certificates for completion of the Bio Preservation Task to
                    the owners of M7i. Please only submit request if you have
                    successfully completed your task)
                  </p>
                </div>
              </div>
            )}


            <section
              className="flex flex-col w-full gap-10 mx-auto my-6 cursor-pointer lg:flex-row"
              onClick={this.toggleMakeGift}
            >



              <div className="w-full space-y-4">
                <div className="flex items-center justify-between px-5 py-4 dashboard-card rounded-xl">
                  <div className="flex flex-row items-center ">
                    <span className="relative flex items-center justify-center w-10 h-10 text-center md:w-16 md:h-16 wallet-bg">
                      <img
                        src="/assets/contribute.png"
                        alt="Gift.png"
                        className="w-14 h-14"
                      />
                    </span>
                    <span className="pl-4 text-base font-bold md:text-2xl all-txt-color">
                      Contribute
                    </span>
                  </div>
                  <span className="cursor-pointer all-txt-color">
                    {isMakeGift ? (
                      <IoIosArrowUp className="w-7 h-7" />
                    ) : (
                      <IoIosArrowDown className="w-7 h-7" />
                    )}
                  </span>
                </div>
              </div>
            </section>

            {isMakeGift && (
              <div className="px-6 py-5 space-y-3 bg-white rounded-xl grid lg:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <h2 className="all-txt-color text-2xl font-bold">Contribute</h2>
                  <p className="all-txt-color">
                    As M7i strides forward in its mission of Bio-Preservation, we find Earth at the verge of disaster. Our commitment has led to substantial investments in developing and maintaining the M7i platform, a cornerstone in safeguarding our planet's biological heritage for future generations. Your support is vital and any contribution that you extend towards the efforts of BWEC to sustain its campaign amplifies our impact. Together, we can ensure that M7i continues to make strides in bio-preservation, leaving a lasting legacy in preserving the Climate for generations to come.
                  </p>
                </div>

                <form>
                  <div className="px-4 rounded-md shadow-sm  items-center  ">
                    {/* <div>
                      <label className="text-sm text-[#0E1F51] font-medium">
                        Crypto Address
                      </label>
                      <input
                        className="w-full bg-[#F1F1F1] py-2 px-3 rounded-md placeholder:text-gary-300 text-gray-600 outline-none "
                        id="balance"
                        name="balance"
                        placeholder="Enter Crypto Address"
                        type="text"
                      />
                    </div> */}
                    <div>
                      <label className="text-sm text-[#0E1F51] font-medium">
                        Total Amount CA${this.state && this.state.coinamount ? this.state.coinamount : '0'}
                      </label>
                      <input
                        className="w-full bg-[#F1F1F1] py-2 px-3 rounded-md placeholder:text-gary-300 text-gray-600 outline-none "
                        placeholder="amount"
                        type="number"
                        value={this.state.coinamount}
                        onChange={this.handelOnchangeInput}
                      />
                      {this.state.coinamountError && this.state.coinamountError ?
                        <div className="text-yellow-700 invalid-feedback text-center">
                          {this.state.coinamountError}
                        </div>
                        : null}
                    </div>
                  </div>
                  <div className="w-64 py-6 mx-auto sm:w-72">
                    <button
                      className=" w-full mx-auto flex justify-center py-3.5 uppercase px-4 text-white text-sm font-semibold rounded-md all-btn-color hover:bg-shine  focus:outline-none"
                      type="button" onClick={() => this.handleSubmitPayment()}
                    >
                      Contribute
                    </button>
                  </div>

                  <div className="grid w-full grid-cols-3 gap-4 rounded-md shadow-sm">
                    <button
                      className="flex justify-center w-full p-3 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none"
                      type="button"
                      onClick={() => this.setState({ coinamount: 100 })}
                    >
                      100$
                    </button>
                    <button
                      className="flex justify-center w-full p-3 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none"
                      type="button"
                      onClick={() => this.setState({ coinamount: 1000 })}
                    >
                      1000$
                    </button>
                    <button
                      className="flex justify-center w-full p-3 mx-auto text-white uppercase transition duration-150 ease-in-out border border-transparent rounded-md text-bases font-meduim all-btn-color focus:outline-none"
                      type="button"
                      onClick={() => this.setState({ coinamount: 9999 })}
                    >
                      9999$
                    </button>
                  </div>
                </form>
              </div>
            )}

            <section className="p-6 mt-8 bg-white rounded-lg shadow-lg">
              <p className="pb-3 text-2xl text-[#0E1F51]">Bio Preservation Task History Graph</p>
              <ApexLineChart getDataforTaskCSuccess={getDataforTaskCSuccess} />
            </section>

            {/* */}
          </div>
        </main >
        {
          this.state.depositIndexCreateModal ? (
            <>
              {this.state.currencyType && this.state.currencyType === "INR" ? (
                <>
                  <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50">
                    <div className="lg:w-[30%] w-full bg-white z-50 mx-auto rounded-lg shadow-lg">
                      <div className="flex items-center justify-between p-2 border-b">
                        <div className="flex items-center">
                          <div className="pl-2">
                            <div className="font-semibold text-lg text-[#0E1F51]">
                              Deposit Balance
                            </div>
                          </div>
                        </div>

                        <div>
                          <button
                            className="inline-flex p-2 rounded-full hover:bg-indigo-50"
                            type="button"
                          >
                            <MdClose
                              className="w-6 h-6"
                              onClick={() => this.handleCloseDepositIndexModal()}
                            />
                          </button>
                        </div>
                      </div>

                      <form>
                        <div className="mt-6">
                          <div className="relative px-4 rounded-md shadow-sm">
                            <label className="text-sm text-[#0E1F51] font-medium">
                              Amount
                            </label>
                            <input
                              className="w-full bg-[#F1F1F1] py-2 px-3 rounded-md placeholder:text-gary-300 text-gray-600 outline-none "
                              id="balance"
                              name="balance"
                              placeholder="Your Coin Balance"
                              type="text"
                              value={
                                this.state.fieldsIndex["amt"]
                                  ? this.state.fieldsIndex["amt"]
                                  : ""
                              }
                              onChange={this.state.inputChangeAmount}
                            />
                          </div>
                        </div>

                        <div className="w-64 py-6 mx-auto mt-6 sm:w-72">
                          <button
                            className=" w-full mx-auto flex justify-center py-3.5 uppercase px-4 text-white text-sm font-semibold rounded-md bg-blue-400 hover:bg-shine  focus:outline-none"
                            type="button"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster bg-black/50">
                    <div className="z-50 w-11/12 mx-auto overflow-y-auto rounded shadow-lg md:max-w-md">
                      <div className="px-6 py-4 text-left bg-white modal-content">
                        <div className="flex items-center justify-between pb-3">
                          <p
                            className="text-2xl font-bold text-[#0E1F51]"
                            onClick={() => this.handleCloseDepositIndexModal()}
                          >
                            Deposit
                          </p>

                          <button
                            className="inline-flex p-2 rounded-full hover:bg-indigo-50"
                            type="button"
                          >
                            <MdClose
                              className="w-6 h-6"
                              onClick={() => this.handleCloseDepositIndexModal()}
                            />
                          </button>

                          {/* <div className="z-50 cursor-pointer modal-close">
                            <div className="text-2xl font-bold text-[#0E1F51]" onClick={() => this.handleCloseDepositIndexModal()}><MdClose className='w-6 h-6' /></div>
                          </div> */}
                        </div>

                        <div className="flex-col col-span-12 rounded-md xl:col-span-3 sm:col-span-6">
                          <div className="w-full text-center">
                            <h3 className="text-2xl font-semibold leading-9 tracking-wide text-[#0E1F51] uppercase 2xl:tracking-widest">
                              Wallet
                            </h3>
                          </div>
                          <div className="flex flex-wrap justify-center mx-auto text-center text-[#0E1F51]">
                            <div className="pb-4 cursor-pointer">
                              {/* <img src="dist/img/bpc-icon.png " alt="" /> */}
                            </div>
                          </div>
                          <div className="flex items-center justify-center w-full pb-4 ">
                            <div className="p-4 bg-gray-200 rounded-xl">
                              <QRCode size={250} className="mx-auto cursor-pointer" value={this.state.elementData && this.state.elementData['address'] ? this.state.elementData['address'] : this.state.addressDepositTime && this.state.addressDepositTime['address'] ? this.state.addressDepositTime['address'] : ''}
                              />
                            </div>
                          </div>
                          <div className="w-full">
                            <h4 className="text-lg font-semibold tracking-widest text-center text-[#0E1F51] uppercase cursor-pointer">
                              Scan Qr code
                            </h4>
                          </div>

                          <div className="flex items-center px-3 py-3 mt-4 bg-gray-200 rounded-md flex-nowrap">
                            <p className="mr-2 overflow-hidden text-lg font-light leading-none text-[#0E1F51] whitespace-nowrap">
                              {this.state.elementData && this.state.elementData['address'] ? this.state.elementData['address'] : this.state.addressDepositTime && this.state.addressDepositTime['address'] ? this.state.addressDepositTime['address'] : ''}
                            </p>
                            <span
                              onClick={() => this.copyCodeToClipboard(this.state.elementData && this.state.elementData['address'] ? this.state.elementData['address'] : this.state.addressDepositTime && this.state.addressDepositTime['address'] ? this.state.addressDepositTime['address'] : '')
                              }
                              className="block px-2 py-2 ml-auto mr-0 text-[#0E1F51] rounded-md cursor-pointer bg-gray-300"
                            >
                              <BiCopy className="w-5 h-5" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : null
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting, user, wallet } = overview ? overview : {};
  return {
    users,
    setting,
    user,
    dashboard,
    wallet,
  };
}
export default connect(mapStateToProps)(Dashboard);
