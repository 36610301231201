import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import Timer from 'otp-timer'
import { BsGlobe } from "react-icons/bs";
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';


class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.otpSubmit = this.otpSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      otpSentLogin: false,
      rememberMe: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.otpSentLogin) {
      return {
        ...nextProps,
        // fieldslogin: {},
        // errorslogin: {},
        otpSentLogin: nextProps.otpSentLogin ? nextProps.otpSentLogin : false

      }
    } else {
      return {
        ...nextProps
      }
    }
  }


  // inputChange(e) {
  //   e.preventDefault();
  //   let { name, value } = e.target;
  //   this.setState({ [name]: value });
  //   let fieldslogin = this.state.fieldslogin;
  //   let errorslogin = this.state.errorslogin;
  //   fieldslogin[name] = value;
  //   errorslogin[name] = "";
  //   // // console.log(name, value);
  //   this.setState({ fieldslogin, errorslogin });
  // }

  inputChange(e) {
    if (e) {
      e.preventDefault();
      const { name, value } = e.target;
      this.setState({ [name]: value });
      let fieldslogin = this.state.fieldslogin;
      let errorslogin = this.state.errorslogin;
      fieldslogin[name] = value;
      errorslogin[name] = "";
      this.setState({ fieldslogin, errorslogin });
    }
  }



  // loginSubmit(e) {
  //   e.preventDefault();
  //   if (this.handleValidationLogin()) {
  //     let { email, password, } = this.state;
  //     this.props.dispatch(userActions.login({ email: email, password: password, }, this.props));
  //   }
  // }



  loginSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    if (this.handleValidationLogin()) {
      let { email, password } = this.state;
      this.props.dispatch(
        userActions.login({ email: email, password: password }, this.props)
      );
    }
  }

  resendSubmit = (e) => {
    // e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, password, } = this.state;
      this.props.dispatch(userActions.login({ email: email, password: password, }, this.props));
    }

  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }


  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //email
    if (!fieldslogin["email"]) {
      formIsValid = false;
      errorslogin["email"] = "Please enter a valid email address.";
    }

    //Password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "The password field is required. ";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  componentDidMount() {

  }

  onClickMenu = (url) => {
    // console.log("url:", url);
    this.props.history.push(url)
  }

  otpSubmit() {
    if (this.handleValidationOTP()) {
      let { token } = this.props.registeruser;
      let { OTP } = this.state;
      this.props.dispatch(userActions.validateLoginOtp({
        token: token,
        otp: OTP
      }, this.props));
    }
  }


  // Define a function to handle OTP input field key press
  handleOTPKeyPress = (e) => {
    if (e.key === "Enter") {
      this.otpSubmit(); // Call otpSubmit when Enter is pressed
    }
  };

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "Please enter a access code!";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }



  componentDidMount() {
    // Check if rememberMe flag is set in localStorage
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    if (rememberMe) {
      this.setState({ email: localStorage.getItem('email'), password: localStorage.getItem('password') });
    }
    this.setState({ rememberMe });
  }
  handleCheckboxChange = () => {
    this.setState(prevState => ({
      rememberMe: !prevState.rememberMe
    }), () => {
      localStorage.setItem('rememberMe', this.state.rememberMe);
    });
  };

  render() {
    let { loading } = this.props;

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <>

          {/* Whole Structure */}

          <div className="overflow-hidden">
            <div className="bg-gray-100 ">
              <div className="flex flex-col justify-center h-screen">
                <div className="flex h-screen overflow-hidden" >
                  <div className="flex flex-col flex-1 w-full h-screen overflow-hidden overflow-y-auto">

                    <div className="flex w-full h-screen md:overflow-hidden overflow-y-auto md:items-center bg-[#1D173F]">

                      <div className="items-center justify-center hidden w-full h-screen bg-[1D173F] lg:flex ">
                        <div className="w-full">
                          <div className="flex justify-center pt-2">
                            <div className="space-y-2">
                              <div className="flex items-center justify-center w-full">
                                <img className="w-24 h-24 lg:w-28 lg:h-28 lg:ml-10" src="assets/M7CoinLogo.png" alt="M7CoinLogo.png" />
                              </div>
                              <h1 className="text-4xl font-extrabold text-white lg:text-5xl">M7<span className="ml-2 text-[#B58633]">Coin Web Wallet</span></h1>
                            </div>
                          </div>
                          <div className="bg-gray-600 h-0.5 w-74 ml-8 mr-10 lg:mt-14 mt-6"></div>
                          <div className="hidden px-4 py-5 space-y-3 lg:flex lg:flex-col lg:mt-5 lg:py-8">
                            <h1 className="text-2xl font-bold text-center text-white lg:text-2xl">Bio Preservation Project</h1>
                            <h1 className="text-lg text-center text-gray-400">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                            <h1 className="flex items-center justify-center text-sm text-center text-gray-400 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-400" />enterwayne.com</a></h1>
                          </div>
                        </div>
                      </div>


                      <div className="flex items-center justify-center w-full h-screen space-y-3 bg_page">
                        <div className="w-full">
                          <div className="flex lg:hidden lg:justify-center ">
                            <div className="mx-auto">
                              <img className="w-20 h-20 mx-auto mt-2 lg:mt-2" src="assets/M7CoinLogo.png" />
                              <h1 className="py-4 text-3xl text-[#B58633] font-extrabold md:text-4xl ">M7<span className="ml-2 text-[#B58633]">Coin Web Wallet</span>
                              </h1>
                            </div>
                          </div>

                          <div className="w-full">
                            <h1 className="sign-text">Sign in</h1>
                          </div>

                          <div className="flex-col w-5/6 mx-auto space-y-4 md:w-5/6 lg:mt-8">

                            <div className="">
                              <label className="text-sm font-medium text-gray-600">Email</label>
                              <input disabled={this.state.otpSentLogin ? true : false} className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["email"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                id="email" name="email" placeholder="" type="email" onChange={this.inputChange}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    this.loginSubmit();
                                  }
                                }}
                              />
                              {this.state.errorslogin["email"] ?
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["email"]}
                                </div>
                                : null}
                            </div>
                            <div className="">
                              <label className="text-sm font-medium text-gray-600">Password</label>
                              <div className="relative">
                                <input
                                  disabled={this.state.otpSentLogin ? true : false}
                                  className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-black placeholder-white placeholder:text-[#A09DC5] ${!this.state.errorslogin["password"] ? "border-opacity-100 border placeholder-white font-base" : "border-opacity-100 border-none ring-2 ring-red-300"}`}
                                  id="password"
                                  name="password"
                                  placeholder=""
                                  type={this.state.showPassword ? "text" : "password"}
                                  onChange={this.inputChange}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      this.loginSubmit();
                                    }
                                  }}
                                />
                                <button
                                  onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                  className="absolute right-4 top-1/2 transform -translate-y-1/2 focus:outline-none"
                                >
                                  {this.state.showPassword ? <RiEyeOffLine /> : <RiEyeLine />}
                                </button>
                              </div>
                              {this.state.errorslogin["password"] &&
                                <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                  {this.state.errorslogin["password"]}
                                </div>
                              }
                            </div>


                            <div className="flex items-center justify-between px-2 mt-2">
                              {/* <div className="flex items-center cursor-pointer">
                                <input id="remember_me" name="remember_me" type="checkbox"
                                  className="h-4 w-4 cursor-pointer text-[#1D173F] focus:ring-[#1D173F] border-gray-300 rounded" />
                                <label htmlFor="remember_me" className="block ml-2 text-sm text-gray-900">
                                  Remember me
                                </label>
                              </div> */}
                              <div className="flex items-center cursor-pointer">
                                <input
                                  id="remember_me"
                                  name="remember_me"
                                  type="checkbox"
                                  className="h-4 w-4 cursor-pointer text-[#1D173F] focus:ring-[#1D173F] border-gray-300 rounded"
                                  checked={this.state.rememberMe}
                                  onChange={this.handleCheckboxChange}
                                />
                                <label htmlFor="remember_me" className="block ml-2 text-sm text-gray-900">
                                  Remember me
                                </label>
                              </div>
                              <div className="text-sm cursor-pointer" onClick={() => this.onClickMenu('forgot')}>
                                <h2 className="font-medium text-[#CB9731] hover:underline">Forgot password?</h2>
                              </div>
                            </div>

                            {this.state.otpSentLogin ? (
                              <div className="flex-col lg:pt-2 lg:mt-10">
                                <div className="">
                                  <label className="text-sm font-medium text-gray-600">Access Code</label>
                                  <input
                                    className={`w-full border px-4 py-2.5 rounded-md focus:outline-none text-base font-normal text-black placeholder-white placeholder:text-[#A09DC5]  ${!this.state.errorslogin["OTP"] ? "'border-opacity-20 border-opacity-100 border placeholder-white font-base " : "border-opacity-100 border-none ring-2 ring-red-300"
                                      }`}
                                    name="OTP"
                                    value={this.state.fieldslogin["OTP"] ? this.state.fieldslogin["OTP"] : ''}
                                    onChange={this.inputChange}
                                    placeholder=""
                                    type="text"
                                    onKeyPress={this.handleOTPKeyPress}
                                  />
                                  {this.state.errorslogin["OTP"] ? (
                                    <div className="mt-1 font-serif text-red-500 invalid-feedback">
                                      {this.state.errorslogin["OTP"]}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}
                          </div>

                          {this.state.otpSentLogin ?
                            <div className="flex items-center justify-between w-5/6 mx-auto mr-auto md:w-3/6 lg:mt-5 ">
                              <div className="flex items-center py-2 ml-2 text-[#0E1F51] lg:ml-0">
                                <Timer textColor={"#0E1F51"} seconds={1} minutes={2} ButtonText="Resend Otp" resend={this.resendSubmit} />
                              </div>
                            </div> : null}

                          {this.state.otpSentLogin ?
                            <div className="mt-6 text-center">
                              <button className="w-5/6 py-2 text-white transition duration-150 ease-in-out rounded-lg cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline md:w-3/6 lg:py-3" type="button" onClick={(e) => this.otpSubmit(e)}>Access Code</button>
                            </div>
                            :
                            <div className="mt-6 text-center">
                              <button className="w-5/6 py-2 mx-auto text-white transition duration-150 ease-in-out rounded-lg cursor-pointer all-btn-color lg:font-bold focus:outline-none focus:underline lg:py-3 md:w-3/6" type="submit" onClick={(e) => this.loginSubmit(e)}>Sign In</button>
                            </div>
                          }

                          <h1 className="text-[#A09DC5] text-center mt-3 lg:mt-8  text-[16px]">Don’t have an account yet? <span onClick={() => this.onClickMenu('/signup')} className="text-[#CB9731] cursor-pointer lg:text-sm text-[15px] hover:underline">Sign Up</span></h1>

                          {/* <div className="px-4 py-4 space-y-2 lg:hidden lg:flex lg:flex-col">
                            <h1 className="px-5 text-[1rem] font-medium text-center text-gray-600 lg:pt-5">Bio Preservation Project</h1>
                            <h1 className="text-[0.9rem] text-center text-gray-600 lg:pt-5">Copyright © 2023 Bruce Wayne Enterprises Corporation - All Rights Reserved.</h1>
                            <h1 className="flex items-center justify-center text-sm text-center text-gray-600 lg:pt-2 lg:text-base"><a className="flex" target="_blank" rel="noopener" href="https://enterwayne.com/"><BsGlobe className="w-5 h-5 mr-1 text-gray-600" />enterwayne.com</a></h1>
                          </div> */}
                        </div>
                      </div>
                    </div>


                    <div id="footer" className="">
                      <div className="w-full px-4 py-2.5 all-btn-color">
                        <div className="text-[13px] text-white tracking-wide space-y-2 text-center pb-6">
                          <strong>© Powered By Bruce Wayne AI Labs</strong>
                          <div className="flex items-center justify-center w-full">  <img src="assets/footerlogo.png" alt="" className="w-12 h-8" /></div>
                          <h3> Copyright © 2014-2023</h3>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>



        </>

      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSentLogin, registeruser, loading } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSentLogin,
    user,
    users,
    registeruser,
    loading,
    authentication
  };
}
export default connect(mapStateToProps)(Login);
// export default NetworkDetector(connect(mapStateToProps)(Login));