import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { userActions, alertActions } from '../../_actions';
import { connect } from 'react-redux';
import moment from 'moment';
import "jspdf-autotable";
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import LoadingOverlay from 'react-loading-overlay';
import './styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { LuCopyCheck, LuCopy } from "react-icons/lu";
import { AiOutlineReload } from "react-icons/ai";

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      keyWord: "",
      pageNo: 1,

      offsetOverflow: 0,
      sizeOverflow: 10,
      keyWordOverflow: "",
      pageNoOverflow: 1,
      formField: {},
      errorField: {},
      dateDetails: {
        txType: null,
        from: Date.now(),
        to: Date.now(),
      },
    }
  }


  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10,

    }
    // this.props.dispatch(userActions.getBptList(data));
    this.props.dispatch(userActions.getBptList(data));
    this.props.dispatch(userActions.getProfileDataPi());
  }

  transactionSearchSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      let reqData = {
        "txType": this.state.txType,
        "keyWord": "",
        "pageNo": 1,
        "size": 10,
        "from": this.state.formField.from,
        "to": this.state.formField.to,
      }
      this.props.dispatch(userActions.getBptList(reqData, this.props));
    }
  }
  inputTypeChange = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let data3 = {
      txType: value === "ALL" ? null : value,
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size,
      "from": this.state.formField.from,
      "to": this.state.formField.to,
    }
    this.props.dispatch(userActions.getBptList(data3));
    if (value === "ALL") {
      this.setState({ txType: null })
    } else {
      this.setState({ txType: value })
    }
  }

  getTx = (e) => {
    e.preventDefault();
    let reqData = {
      "txType": this.state.txType,
      "keyWord": "",
      "pageNo": 1,
      "size": 10,
      "from": (this.state.dateDetails.from),
      "to": (this.state.dateDetails.to),
    }
    this.props.dispatch(userActions.getBptList(reqData, this.props));
  }


  getDate = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let dateDetails = this.state.dateDetails;
    dateDetails[name] = value;
    this.setState({ dateDetails });
  }

  inputChange = (event) => {
    const { formField, errorField } = this.state;
    formField[event.target.name] = event.target.value;
    errorField[event.target.name] = "";
    this.setState({ formField, errorField });
  }



  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      // txType: this.state.txType ? this.state.txType : null,
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size,
    }
    this.props.dispatch(userActions.getBptList(datatemp));
  }

  handlePageClickOverflow = (data) => {
    let offset = Math.ceil(data.selected * this.state.sizeOverflow);
    this.setState({ offsetOverflow: offset, page: data.selected });
    let dataTemp = {
      // overflowTxList: this.state.overflowTxList ? this.state.overflowTxList : null,
      "keyWord": this.state.keyWordOverflow,
      "pageNo": data.selected + 1,
      "size": this.state.sizeOverflow,
    }
    this.props.dispatch(userActions.getUserTxListOverFlow(dataTemp));
  }

  handleValidation = () => {
    let errorField = {};
    let formIsValid = true;
    this.setState({ errorField: errorField });
    return formIsValid;
  }

  handleFromDate = (event) => {
    const { dateDetails } = this.state;
    dateDetails["from"] = event.target.value;

    this.setState({ dateDetails });
  }
  handleToDateMultiDate = (event) => {
    event.preventDefault();
  }

  handleToDate = (event) => {
    const { dateDetails } = this.state;
    dateDetails["to"] = event.target.value;
    this.setState({ dateDetails });
  }

  copyTxIdToClipboard = (txId) => {
    const tempInput = document.createElement('input');
    tempInput.value = txId;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.setState({ copiedTxId: txId });
    this.props.dispatch(alertActions.success("Copied!"));
  };

  render() {

    let { users } = this.props;
    let { ListgetbptList, loading, TotalgetbptList } = users;
    const { copiedTxId } = this.state;
    console.log("usersusersusersusersusers", ListgetbptList);
    // console.log("overflowTotalgetbptList_____________RENDER_______", overflowTotalgetbptList);


    return (

      <>

        <div >
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className='overflow-hidden'>
              <div className='overflow-y-auto'>
                <div className='p-8'>
                  {/* <div className='py-4 bg-white rounded-lg shadow-lg'>
                    <div className='flex-wrap items-end justify-between px-8 space-x-0 lg:flex lg:space-x-0'>

                      <div className="relative w-56 text-gray-800 border border-gray-300 appearance-none">
                        <label for="frm-whatever" className="sr-only">My field</label>
                        <select className="w-full p-2 text-sm font-normal bg-white outline-none appearance-none" onChange={this.inputTypeChange}
                          id="txType"
                          name="txType">
                          <option value="ALL">Please choose&hellip;</option>
                          <option value="SEND">SEND</option>
                          <option value="RECEIVED">RECEIVED</option>
                          <option value="DEPOSIT">DEPOSIT</option>
                          <option value="BUY_M7I">BUY_M7I</option>
                          <option value="REF">REF</option>
                          <option value="RECEIVED_FROM_ADMIN">RECEIVED_FROM_ADMIN</option>
                          <option value="WITHDRAW">WITHDRAW</option>
          
                        </select>
                        <div className="absolute top-0 bottom-0 right-0 flex items-center px-2 text-gray-700 border-l pointer-events-none">
                          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>

                      <div className="flex flex-wrap items-end mt-2 space-x-0 space-y-3 text-gray-800 md:space-x-4 lg:space-y-3 md:space-y-0">
                        <div className="">
                          <label style={{ color: "#6D6997" }} className="text-xs font-bold text-gray-400">From Date</label><br />
                          <input
                            onChange={this.inputChange}
                            name="from"
                            value={this.state.formField["from"] ? this.state.formField["from"] : ''}
                            type="date" className="bg-white cursor-pointer outline-none lg:py-1.5 p-2 w-56	text-gray-700 border focus:text-gray-600 rounded-md " />
                        </div>
                        <div className="">
                          <label style={{ color: "#6D6997" }} className="text-xs font-bold text-white/50">To Date</label><br />
                          <input
                            onChange={this.inputChange}
                            name="to"
                            value={this.state.formField["to"] ? this.state.formField["to"] : ''}
                            type="date" className="bg-white cursor-pointer outline-none lg:py-1.5 p-2 w-56 text-gray-700 border focus:text-gray-600	rounded-md" />
                        </div>
                        <button onClick={this.transactionSearchSubmit} className="items-center p-2 px-8 text-white rounded-md all-btn-color">
                          Search
                        </button>
                      </div>
                    </div>
                  </div> */}

                  <section className="p-6 mt-5 space-y-4 bg-white border rounded-md">
                    <div className="flex flex-wrap items-center justify-between w-full ">
                      <div className="">
                        <h3 className="text-xl font-semibold text-[#253992] whitespace-nowrap">BPT History</h3>
                      </div>

                      {/* <div className='flex space-x-3'>
                        <button onClick={() => this.handleReloadTab()} type="button" class=" whitespace-nowrap px-3 py-2 font-semibold text-lg rounded-r-lg bg-[#3B82F6]/10 rounded-lg text-[#253992] flex items-center justify-center"><span className='pr-4 space-x-3'><AiOutlineReload className='w-5 h-5' /></span>Reload</button>
                      </div> */}
                    </div>

                    <div className="pb-2 mt-4 overflow-hidden">
                      <div className="max-w-full overflow-auto">
                        <div className="inline-block min-w-full">
                          <div className="">

                            <table className="min-w-full">
                              <thead className="border border-gray-300 all-btn-color">
                                <tr className="text-white">
                                  <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">S/n</th>
                                  <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">date</th>
                                  <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">user Name</th>
                                  <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap"> Type</th>
                                
                                  {/* <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">amount</th>
                                  <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">Received From</th> */}
                                  <th className="px-3 py-3 text-sm font-semibold text-center uppercase border border-gray-300 whitespace-nowrap">status</th>
                                </tr>
                              </thead>
                              <tbody>

                                {
                                  ListgetbptList && ListgetbptList.length > 0 ?
                                    ListgetbptList.map((element, index) => (
                                      <>
                                        {element && element ?
                                          <tr className="text-center border rounded-lg">
                                            <td className="px-3 py-3 text-xs text-center text-[#837BB0] uppercase bg-white border border-gray-300 whitespace-nowrap">{this.state.offset + index + 1}</td>

                                            <td className="px-3 py-3 text-xs text-center text-[#837BB0] uppercase bg-white border border-gray-300 whitespace-nowrap">{moment(new Date(parseInt(element && element.createdAt ? element.createdAt : "-"))).utcOffset("+05:30").format("DD-MM-YYYY")}</td>

                                            <td className="px-3 py-3 text-xs text-center text-[#837BB0] uppercase bg-white border border-gray-300 whitespace-nowrap">{element && element.userName ? element.userName : "-"}</td>

                                            <td className="px-3 py-3 text-xs text-center text-[#837BB0] uppercase bg-white border border-gray-300 whitespace-nowrap">{element && element.type ? element.type : "-"}</td>



                                            {element.status==0&&<td className="px-3 py-3 text-xs text-center text-yellow-700 uppercase bg-white border border-gray-300 whitespace-nowrap">Pending</td>}
                                            {element.status==1&&<td className="px-3 py-3 text-xs text-center  text-green-900 uppercase bg-white border border-gray-300 whitespace-nowrap">Success</td>}
                                            {element.status==2&&<td className="px-3 py-3 text-xs text-center text-red-800 uppercase bg-white border border-gray-300 whitespace-nowrap">Rejected</td>}

                                            {/* {element && element.txType && element.txType == "WITHDRAW" || element && element.txType && element.txType == "DEPOSIT" ? */}
                                            {/* <td className="px-3 py-3 text-xs text-center text-[#837BB0] uppercase bg-white border border-gray-300 whitespace-nowrap" title={element.txId} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                              <span className='flex items-center justify-center'>{element.txId && element.txId.length > 50 ? element.txId.substring(0, 30) + '...' : '-'}
                                                {element.txId && element.txId.length > 50 && (
                                                  <button
                                                    onClick={() => this.copyTxIdToClipboard(element.txId)}
                                                    className="ml-2 text-center text-blue-500 cursor-pointer"
                                                    disabled={copiedTxId === element.txId} // Disable the button if the txId has been copied
                                                  >
                                                    {copiedTxId === element.txId ? <LuCopyCheck className='w-4 h-4 text-[#007F00]' /> : <LuCopy className='w-4 h-4' />}
                                                  </button>
                                                )}</span>
                                            </td> */}
                                            {/* :
                                              <td className="px-3 py-3 text-xs text-center text-[#837BB0] uppercase bg-white border border-gray-300 whitespace-nowrap"> "-"</td>} */}

                                            {/* <td className="px-3 py-3 text-xs text-center text-[#837BB0] uppercase bg-white border border-gray-300 whitespace-nowrap">{element && element.amount ? element.amount : "-"}</td> */}

                                            {/* {
                                              element && element.txType && element.txType == "WITHDRAW" || element && element.txType && element.txType == "DEPOSIT" ?
                                                <td className="px-3 py-3 text-xs text-center text-[#837BB0] uppercase bg-white border border-gray-300 whitespace-nowrap" title={element.toAddress} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                                  {element && element.toAddress.substring(0, 20) ? element.toAddress.substring(0, 20) + "..." : element.toAddress}</td>
                                                :
                                                <td className="px-3 py-3 text-xs text-center text-[#837BB0] uppercase bg-white border border-gray-300 whitespace-nowrap">{element && element.userRecId && element.userRecId.userName ? element.userRecId.userName : "-"}</td>
                                            } */}



                                         

                                          </tr >
                                          : null
                                        }

                                      </>
                                    )) : <tr><td colSpan={8} className="text-center text-gray-700">Data Not Found</td></tr>
                                }

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {
                        isMobile ?
                          <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              TotalgetbptList && TotalgetbptList > 10 ?
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={TotalgetbptList / this.state.size}
                                  marginPagesDisplayed={0}
                                  pageRangeDisplayed={2}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                            {
                              TotalgetbptList && TotalgetbptList > 10 ?
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={TotalgetbptList / this.state.size}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                />
                                : null}
                          </nav>
                      }
                    </div>
                  </section>
                </div>

              </div>
            </div>
          </section>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting
  };
}
export default connect(mapStateToProps)(Transaction);
